import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { KebabCase, ValidateField } from '../../assets/scripts/helpers';
import '../input/input.scss';

const Select = props => {
    const {
            className,
            dataQeId,
            defaultValue,
            selectHiddenOption,
            selectHiddenLabel,
            selectName:name,
            selectLabel:label,
            selectOptions:options,
            selectRequired:required
        } = props;

    const [select, setSelect] = useState({
            value: defaultValue || '',
            focus: false,
            error: {
                status: false,
                message: ''
            }
        }),
        {
            value,
            focus,
            error: {
                status,
                message
            }
        } = select;

    const fieldId = name && KebabCase(name),
        fieldsetClass = ` ${status ? 'error' : ''} ${focus ? 'focus' : ''}`,
        selectClass = (value||selectHiddenOption) !== ' ' ? 'push' : '',
        messageClass = status ? 'message--error' : '';

    useEffect(() => {
        setSelect({...select, value: defaultValue});
    }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps


    const getClasses = () => {
        let classes = [];

        if (className) {
            classes.push(className);
        }

        return classes.join(' ');
    }

    return (
        <fieldset className={getClasses() + fieldsetClass}>
            <select
                id={fieldId}
                className={selectClass}
                value={value}
                data-qe-id={`${dataQeId}-${fieldId}`}
                onFocus={e => setSelect({...select, focus: true})}
                onBlur={e => ValidateField(e, (v) => {
                    setSelect({
                        ...select,
                        focus: false,
                        error: {
                            status: v.status,
                            message: v.message
                        }
                    })
                })}
                onChange={(e) => {
                    setSelect({...select, value: e.target.value});
                    if (props.onChangeCallback) {
                        props.onChangeCallback(e);
                    }
                }}
                required={required}
            >
                {selectHiddenOption &&
                    <option hidden>{selectHiddenOption}</option>
                }
                {options.map((option, i) => {
                  return (
                    <option key={i} value={option}>
                        {option}
                    </option>
                  );
                })}
            </select>
            {label &&
                <label htmlFor={fieldId}>{label}</label>
            }
            {!label && selectHiddenLabel &&
                <label
                    className="screen-reader-text"
                    htmlFor={fieldId}
                >
                    {selectHiddenLabel}
                </label>
            }
            <svg width="6" height="11" viewBox="0 0 6 11">
                <path d="M6 5.5c0 .23-.08.45-.24.63l-4.34 4.61a.8.8 0 01-1.18 0 .93.93 0 010-1.25L4 5.5.24 1.51a.93.93 0 010-1.25.8.8 0 011.18 0l4.34 4.61c.16.18.24.4.24.63z" />
            </svg>
            <span className={`message ${messageClass}`}>
                {required &&
                    <React.Fragment>
                        <sup>*</sup>Required
                    </React.Fragment>
                }
                {message &&
                    <React.Fragment>: {message}</React.Fragment>
                }
            </span>
        </fieldset>
    );
};

Select.propTypes = {
    dataQeId: PropTypes.string.isRequired,
    selectHiddenOption: PropTypes.string,
    selectName: PropTypes.string.isRequired,
    selectLabel: PropTypes.string,
    selectOptions: PropTypes.array.isRequired
};

Select.defaultProps = {
    value: '',
};

export default Select;
