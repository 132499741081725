import React, { useRef } from 'react';
import * as Components from '../components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { normalizeData } from '../../assets/scripts/helpers';
import Link from '../link';
import './text-block.scss';

const TextBlock = (props) => {
    const {
        /*
            This is garbage from preview
        */
        className,
        content,
        contentful_id,
        dataQeId,
        id,
        ...attributes
    } = props,
    wrapper = useRef(),
    activeSection = props.activeSection || '';
    const classes = className || '';
    const parent = {
        contentful_id,
        id
    };
    const json = props.hasOwnProperty('json') ? props.json : props;

    let count = 0;

    const renderEmbed = (node) => {
        // Default preview values
        let embeddedProps = node.data,
            embeddedSysInfo = {
                id: 'preview',
                contentful_id: 'preview',
            },
            embeddedComponentName = node.data._type && node.data._type;

        // Change if the data is coming from gatsby-source-contentful
        if (typeof node.data.target === 'object') {
            embeddedProps = node.data.target.fields;
            embeddedSysInfo = node.data.target.sys;
            embeddedComponentName = node.data.target.sys.contentType.sys.contentful_id;

            normalizeData(embeddedProps);
        }

        const module = Object.keys(Components.default).filter(component => component.toLowerCase() === embeddedComponentName.toLowerCase());

        if (!module.length) {
            return false;
        }

        return React.createElement(
            Components.default[module], {
                ...embeddedProps,
                ...attributes,
                parent: parent,
                callback: options.callback,
                contentful_id: embeddedSysInfo.contentful_id,
                dataQeId: dataQeId || embeddedComponentName,
                id: embeddedSysInfo.id,
                activeSection: activeSection,
            }
        );
    }

    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: (node) => {
                const linkText = node.content[0].value;
                const linkHref = node.data.uri;

                const {
                    section
                } = attributes;

                const link = {
                    contentful_id: ('000' + count++).slice(-3)
                };

                return (
                    <Link
                        dataQeId={props.dataQeId}
                        link={link}
                        parent={parent}
                        text={linkText}
                        url={linkHref}
                        section={section}
                    />
                )
            },
            [INLINES.EMBEDDED_ENTRY]: (node) => {
                // Give the code a beat 💓
                    setTimeout(() => {
                        if(wrapper.current && !wrapper.current.classList.contains('inline-embed')) {
                            wrapper.current.classList.add('inline-embed');
                        }
                    }, 0);

                return renderEmbed(node);
            },
            [INLINES.ENTRY_HYPERLINK]: (node) => {
                normalizeData(node.data.target.fields);

                const entryText = node.content[0].value;
                const entryHref = node.data.target.fields.pathname || node.data.target.fields.url;
                const entryTarget = node.data.target.fields.target;
                const entryTitle = node.data.target.fields.title;
                const entryModal = node.data.target.fields.modal;
                const link = {
                    contentful_id: node.data.target.sys.contentful_id,
                    id: node.data.target.sys.id
                };

                return (
                    <Link
                        className="text-block-link"
                        dataQeId={props.dataQeId}
                        link={link}
                        parent={parent}
                        text={entryText}
                        title={entryTitle}
                        target={entryTarget}
                        url={entryHref}
                        modal={entryModal}
                        {...attributes}
                    />
                )
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                return renderEmbed(node);
            }
        },
        callback: props.callback
    }

    // documentToReactComponents loops through each part of the json
    // and attempts to match up with the above options
    return (
        <div className={`text-block ${classes}`} ref={wrapper}>
            { documentToReactComponents(json, options) }
        </div>
    );
}

TextBlock.defaultProps = {
    dataQeId: 'rogue'
};

export default TextBlock;
