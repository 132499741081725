import React from 'react';
import PropTypes from 'prop-types';
import TextBlock from '../text-block';
import './two-column-text-block.scss';

const TwoColumnTextBlock = props => {
    const {
        contentful_id,
        id,
        headline,
        introText,
        columnWidth: fifths,
        leftContent,
        leftContentTextSize,
        rightContentRichText,
        ctctLocalePref,
        ...attributes
    } = props,
    parent = {
        contentful_id,
        id
    };

    return (
        <div className="two-column-text-block">
            {(headline || introText) && (
                <div className="two-column-text-block__header">
                    {introText && (
                        <p className="two-column-text-block__intro-text">
                            {introText}
                        </p>
                    )}
                    {headline && <h2>{headline}</h2>}
                </div>
            )}
            <div
                className={`two-column-text-block__body ${
                    fifths ? 'two-column-text-block__body--fifths' : 'two-column-text-block__body--halves'
                }`}
            >
                <div className={`left-content left-content--${leftContentTextSize.toLowerCase()}`}>
                    <h3>{leftContent}</h3>
                </div>
                <div className="right-content">
                    <TextBlock
                        {...rightContentRichText}
                        {...attributes}
                        parent={parent}
                        contentful_id="tct"
                        dataQeId="two-column-text"
                        ctctLocalePref={ctctLocalePref}
                    />
                </div>
            </div>
        </div>
    );
};

TwoColumnTextBlock.propTypes = {
    contentful_id: PropTypes.string,
    id: PropTypes.string,
    headline: PropTypes.string,
    introText: PropTypes.string,
    columnWidth: PropTypes.bool.isRequired,
    leftContent: PropTypes.string.isRequired,
    leftContentTextSize: PropTypes.string,
    rightContentRichText: PropTypes.object,
    ctctLocalePref: PropTypes.string,
};

export default TwoColumnTextBlock;
