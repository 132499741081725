import React from 'react';
import PropTypes from 'prop-types';

const ShoppingCart = props => {
    const { width, height } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 28 27">
            <g
                transform="translate(1 1)"
                stroke="#FFF"
                strokeWidth="1.744"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M16.278 4.36L12.21.29 8.14 4.36"/>
                <path d="M22.092 4.36L18.023.29l-2.907 2.908"/>
                <path d="M4.506 4.36H25.29l-2.326 12.21H6.686L3.779.29H.291"/>
                <circle cx="7.849" cy="22.384" r="2.326"/>
                <circle cx="21.802" cy="22.384" r="2.326"/>
            </g>
        </svg>
    );
};

ShoppingCart.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

ShoppingCart.defaultProps = {
    width: 28,
    height: 27,
};

export default ShoppingCart;
