/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import locales from '../config/locales';
import {
    getPathnameBasedOnLocale
} from '../assets/scripts/helpers';
import useSiteMetadata from '../hooks/use-site-metadata';

const SEO = props => {

    const {
        titleTag,
        excludeRetentionScienceFromTitle: exclude,
        siteTitle,
        description,
        image: {
            file: {
                url: imageUrl
            }
        },
        children,
        lang,
        location,
        schemas,
        canonicalUrl,
        disableOptimizely,
        metaRobots,
        optimizelyProjectId
    } = props,
    localeKeys = Object.keys(locales),
    { siteURL } = useSiteMetadata(),
    titleTemplate = exclude ? `%s` : `%s | ${siteTitle}`;

    let pathname = location.pathname,
        canonicalUrlConstructed = (canonicalUrl || `${siteURL}${pathname}`);

    const showAppleBanner = () => {
        if (typeof window !== undefined) {
            const bannerUrl = '/account-home';

            if (bannerUrl === pathname) {
                return <meta name="apple-itunes-app" content="app-id=880731821, affiliate-data=ct=sops-tk-banner&pt=215849" />
            }
        }
    }

    function handleCookieSettingsClick() {
        try {
            window.CookieConsent.renew();
        } catch (error) {
            if (window?.console) {
                console.warn(error);
            }
        }
    }

    useEffect(() => {
        // Check if Cookiebot script is already loaded
        if (!window.CB_Loaded) {
            // Load Cookiebot script
            const script = document.createElement('script');
            script.id = 'Cookiebot';
            script.src = 'https://consent.cookiebot.com/uc.js';
            script.setAttribute(
                'data-cbid',
                'be778623-accc-4b0e-bccd-8594803e39f3'
            );
            script.setAttribute('data-blockingmode', 'auto');
            script.type = 'text/javascript';
            script.async = false;
            document.head.appendChild(script);

            // Mark Cookiebot as loaded
            window.CB_Loaded = true;
        }

        window.addEventListener("CookieConsentReceived", () => {
            const analyticsConsent = window?.Cookiebot?.consents?.statistics; // Check for analytics/statistics consent
            if (analyticsConsent) {
                const script = document.createElement("script");
                script.src = `https://www.googletagmanager.com/gtag/js?id=UA-11145358-7`;
                script.async = true;
                document.head.appendChild(script);
            
                script.onload = () => {
                    window.dataLayer = window.dataLayer || [];
                    function gtag() {
                        window.dataLayer.push(arguments);
                    }
                    gtag("js", new Date());
                    gtag("config", "UA-11145358-7");
                };
            }
        });


        if (window.location.pathname === '/privacy-policy') {
            setTimeout(() => {
                const elementToReplace = document.querySelector(
                    '#privacy-policy div.one-column-text-block__footer div, #privacy-policy div.one-column-text-block__footer p'
                );

                if (elementToReplace) {
                    const originalContent = elementToReplace.innerHTML;
                    const replacedContent = originalContent.replace(
                        'cookie-settings-link',
                        '<a class="cookie-settings-link" id="cookie-settings-link" href="javascript:void(0)">here</a>'
                    );
                    
                    elementToReplace.innerHTML = replacedContent;

                    // Add click event to the cookie-settings-link
                    const cookieSettingsLink = document.getElementById(
                        'cookie-settings-link'
                    );
                    if (cookieSettingsLink) {
                        cookieSettingsLink.addEventListener('click', () => {
                            handleCookieSettingsClick();
                        });
                    }
                }

            }, 1000);
        }

    }, []);

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={titleTag}
            titleTemplate={titleTemplate}
        >
            <meta name="description" content={description} />

            <meta property="og:title" content={`${titleTag} | Retention Science`} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="product" />
            <meta property="og:url" content={`${siteURL}${pathname}`} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:image:alt" content="Retention Science logo" />
            <meta property="og:site_name" content="Retention Science" />
            <meta property="og:locale" content="en_US" />

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@retentionscience"/>
            <meta name="twitter:url" content={`${siteURL}${pathname}`}/>
            <meta name="twitter:title" content={`${titleTag} | Retention Science`}/>
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={imageUrl} />
            <meta name="twitter:image:alt" content="Retention Science logo"/>
            <meta name="twitter:account_id" content="25960305"/>
            <meta property="fb:pages" content="24860070976"/>
            <meta name="google-site-verification" content="aJrrqn64iJupriwWUxLGIgnAwknLKg6erqWf1tdZ_No" />

            {/*app store banner content*/}
            <link rel="apple-touch-icon" href="https://images.ctfassets.net/nw29p03vv8ee/46Rhu9039bWpafzeo7qRt6/44ed34652d735fd01b9ac323fea272da/Cortex-logo.png" />
            <link rel="android-touch-icon" href="https://images.ctfassets.net/nw29p03vv8ee/46Rhu9039bWpafzeo7qRt6/44ed34652d735fd01b9ac323fea272da/Cortex-logo.png" />
            <meta name="google-play-app" content="app-id=com.constantcontact.toolkit&referrer=utm_campaign%3DSOPS-tk-banner%26utm_medium%3Dsmartbanner%26utm_source%3Dweb" />
            {showAppleBanner()}

            {metaRobots !== 'noindex, nofollow' && (
                <link rel="canonical" href={canonicalUrlConstructed} />
            )}


            {localeKeys.map((localeKey, i) => {
                let href = '',
                    pathnameBasedOnLocale = '';

                pathnameBasedOnLocale = getPathnameBasedOnLocale(pathname, localeKey);

                // remove trailing slash from non-root pathname
                if (pathnameBasedOnLocale !== '/' && pathnameBasedOnLocale.endsWith('/')) {
                    pathnameBasedOnLocale = pathnameBasedOnLocale.substring(0, pathnameBasedOnLocale.length - 1);
                }

                href = `${siteURL}${pathnameBasedOnLocale}`;

                return <link key={localeKey} rel="alternate" hreflang={localeKey === 'en' ? 'x-default' : locales[localeKey].localeCode} href={href} />
            })}

            <script type="application/ld+json">
                {`{
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "url": "${siteURL}${pathname}",
                    "description": "${description}",
                    "name": "${titleTag} | Retention Science",
                    "image": "${imageUrl}"
                }`}
            </script>

            {schemas && schemas.length > 0 && (
                schemas.map((obj, i) => {
                    return (
                        <script key={i} type="application/ld+json">
                            {obj.internal.content}
                        </script>
                    )
                })
            )}

            {!disableOptimizely && (
                <link rel="prefetch" href={`https://cdn.optimizely.com/js/${optimizelyProjectId ? optimizelyProjectId : '2125675982'}.js`}/>
            )}
            {!disableOptimizely && (
                <link rel="preconnect" href="https://logx.optimizely.com" />
            )}
            {!disableOptimizely && (
                <script src={`https://cdn.optimizely.com/js/${optimizelyProjectId ? optimizelyProjectId : '2125675982'}.js`} />
            )}

            {children}
        </Helmet>
    );
}

SEO.propTypes = {
    titleTag: PropTypes.string,
    excludeRetentionScienceFromTitle: PropTypes.array,
    siteTitle: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.object,
    lang: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ])
};

SEO.defaultProps = {
    titleTag: 'Retention Science',
    siteTitle: 'Retention Science',
    excludeRetentionScienceFromTitle: null,
    description: 'Award-winning A.I.-driven email and SMS ecommerce platform for email marketing automation. Sell more online in less time. Schedule your free demo.',
    image: {
        file: {
            url: "https://images.ctfassets.net/t21gix3kzulv/2p7Id1a8N5yRSczCxNOyTP/fa2a7a27be1107fd587fa0e335fa095a/ctct_logo_facebook_opengraph.png"
        }
    },
    lang: 'en'
};


export default SEO;
