import React, { useLayoutEffect } from 'react';
import { RecoilRoot } from 'recoil';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Page = (props) => {
    const {
        pageContext: {
            pageData: {
                section,
                headerComponent,
                footerComponent,
                chat,
                html,
                banner,
                seoComponent: {
                    titleTag,
                    metaDescription,
                    excludeRetentionScienceFromTitle,
                    //metaRobots,
                    canonicalUrl,
                    childrenContentfulSeoSchemasJsonNode,
                    image,
                    imageAlt,
                    disableOptimizely,
                    optimizelyProjectId
                },
            }
        },
        location
    } = props,
    threshold = 30;

    let increment = 0;

    useLayoutEffect(() => {
        if (typeof window !== 'undefined' && !disableOptimizely) {

            const reactivateOptimizely = () => {
                const optlyPages = window.optimizely.get("data").pages;

                function activateOptlyPage(optlyPage) {

                    // Force deactivation of the page
                    window.optimizely.push({
                        type: "page",
                        pageName: optlyPages[optlyPage].apiName,
                        isActive: false
                    });

                    // Reactivate the page. This will force Optimizely to evaluate the conditions again as if reloading the DOM.
                    window.optimizely.push({
                        "type": "page",
                        "pageName": optlyPages[optlyPage].apiName
                    });
                }

                for (const page in optlyPages){
                    activateOptlyPage(page);
                }
            }

            const isOptimizelyReady = () => {
                // Check {threshold} times
                if (increment < threshold) {
                    // If the data object is found reactivate optimizely
                    if (window.optimizely && typeof window.optimizely.data !== 'undefined') {
                        reactivateOptimizely();
                        return;
                    }

                    // If the data object is not found try again after 100ms
                    increment++;
                    setTimeout(isOptimizelyReady, 100);
                } else {
                    // Fail if Optimizely has not loaded after checking {threshold} times
                    console.warn('Optimizely failed to load');
                }

            };

            isOptimizelyReady();
        }

        if (typeof document !== 'undefined' && disableOptimizely) {
            const scripts = document.getElementsByTagName('script'),
                convertedNodeList = Array.from(scripts),
                optimizelyGeoScript = convertedNodeList.find(script => script.src === 'https://cdn3.optimizely.com/js/geo2.js');

            if (optimizelyGeoScript) {
                optimizelyGeoScript.parentNode.removeChild(optimizelyGeoScript)
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            {titleTag && (
                <SEO
                    titleTag={titleTag}
                    excludeRetentionScienceFromTitle={excludeRetentionScienceFromTitle}
                    description={metaDescription}
                    schemas={childrenContentfulSeoSchemasJsonNode}
                    image={image || undefined}
                    imageAlt={imageAlt || undefined}
                    canonicalUrl={canonicalUrl}
                    location={location}
                    disableOptimizely={disableOptimizely}
                    optimizelyProjectId={optimizelyProjectId}
                    //metaRobots={metaRobots}  //CTCTFOS-19130 Removing temporarily to add noindex, nofollow globally
                >
                    <meta name="robots" content="noindex, nofollow" />
                    <link rel="preconnect" href="https://images.ctfassets.net" />
                </SEO>
            )}
            <RecoilRoot>
                <Layout section={section} banner={banner} header={headerComponent} footer={footerComponent} chat={chat} location={location} html={html} />
            </RecoilRoot>
        </React.Fragment>
    )
};

export default Page;
