import React from 'react';
import PropTypes from 'prop-types';
import TwoStepSignupForm from '../two-step-signup-form';
import Breadcrumbs from '../breadcrumbs';
import Image from '../image';
import CtaButton from '../cta-button';
import TextBlock from '../text-block';
import FullImageCardWrapper from '../full-image-card';
import Html from '../html';
import './hero.scss';

const Hero = props => {
    const {
        contentful_id,
        id,
        introText,
        breadcrumbs,
        headline,
        subHeading,
        ctaTypes,
        textAfterCta,
        imageOrFullImageCard,
        ...attributes
    } = props,
    parent = {
        contentful_id,
        id
    };

    const setClasses = () => {
        let classes = '';

        switch(imageOrFullImageCard.__typename) {
            case 'ContentfulFullImageCardWrapper':
                    classes = 'image-card';
                break;
            case 'ContentfulImage':
                    classes = 'image';
                break;
            case 'ContentfulHtml':
                    classes = 'html';
                break;
            default:
        }

        return classes;
    };

    return (
        <div className={`hero hero--${setClasses()}`}>
            <div className="hero__content">
                {breadcrumbs && <Breadcrumbs {...breadcrumbs} {...attributes} dataQeId="hero" />}

                <h1 className="hero__small-text">{introText}</h1>

                <p className="hero__headline">{headline}</p>

                {subHeading &&
                    <h2 className="hero__subhead">
                        <TextBlock {...subHeading} {...attributes} dataQeId="hero" />
                    </h2>
                }

                {ctaTypes && ctaTypes.map((component,i) => {
                    const {
                        contentful_id,
                        id
                    } = component;


                    return (
                        <div className="hero__cta-type" key={i}>
                            {component.hasOwnProperty('buttonText') && (
                                <CtaButton
                                    {...component}
                                    {...attributes}
                                    dataQeId="hero"
                                    parent={parent}
                                    contentful_id={contentful_id}
                                />
                            )}
                            {component.hasOwnProperty('twoStepCaption') && (
                                <TwoStepSignupForm
                                    {...component}
                                    {...attributes}
                                    parent={parent}
                                    dataQeId="hero"
                                    contentful_id={contentful_id}
                                    id={id}
                                />
                            )}
                        </div>
                    )
                })}
                {textAfterCta && (
                    <div className="hero__supporting-text">
                        <TextBlock {...textAfterCta} {...attributes} dataQeId="hero" />
                    </div>
                )}
            </div>
            {imageOrFullImageCard && (
                <div className={`hero__${setClasses()}`}>
                    {imageOrFullImageCard.__typename === 'ContentfulFullImageCardWrapper' &&
                        <FullImageCardWrapper {...imageOrFullImageCard} {...attributes} />
                    }

                    {imageOrFullImageCard.__typename === 'ContentfulImage' &&
                        <Image {...imageOrFullImageCard} lazyload={false} />
                    }

                    {imageOrFullImageCard.__typename === 'ContentfulHtml' &&
                        <Html {...imageOrFullImageCard} />
                    }
                </div>
            )}
        </div>
    );
};

Hero.propTypes = {
    breadcrumbs: PropTypes.object,
    contentful_id: PropTypes.string,
    ctaTypes: PropTypes.array,
    headline: PropTypes.string.isRequired,
    id: PropTypes.string,
    introText: PropTypes.string,
    imageOrFullImageCard: PropTypes.object,
    subHeading: PropTypes.object,
    textAfterCta: PropTypes.object
};

export default Hero;
