import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CtaButton from '../cta-button';
import Cookies from 'universal-cookie';
import { getCookieDomain } from '../../assets/scripts/helpers';

import './two-step-signup-form.scss';

const TwoStepSignupForm = props => {
    const {
            contentful_id,
            dataQeId,
            id,
            inputPlaceholder,
            submitButtonText,
            twoStepCaption,
            ...attributes
        } = props,
        [value, setValue] = useState(''),
        cookies = new Cookies(),
        input = useRef(null);

    useEffect(() => {
        let savedValues = cookies.get('suf-values'),
            thisCookie = '';

        if (savedValues) {
            savedValues = savedValues.split('|');

            for (let i = 0, l = savedValues.length; i < l; i++) {
                if (savedValues[i].includes('siteContactEmailLogin')) {
                    thisCookie = savedValues[i].split('=')[1];

                    /**
                     * this prevents a bug where the user hits
                     * back on the SUF before the value
                     * is carried over.
                     */
                    if (thisCookie.includes('siteContact')) {
                        cookies.set('suf-values', '', {
                            path: '/',
                            domain: getCookieDomain(),
                            maxAge: 15552000, // 15,552,000s = 180 days
                        });
                        thisCookie = null;
                    }
                }
            }

            input.current.value = thisCookie;
        }

    }, [input]); // eslint-disable-line react-hooks/exhaustive-deps

    function handleFormSubmit(event) {
        event.preventDefault();

        cookies.set('suf-values', `siteContactEmailLogin=${value}`, {
            path: '/',
            domain: getCookieDomain(),
            maxAge: 15552000, // 15,552,000s = 180 days
        });

        window.location.href = event.target.action;
    }

    return (
        <form
            id={`${dataQeId}-signupform`}
            name="signup_form"
            className="two-step"
            action="/trial/signup.jsp"
            method="get"
            onSubmit={handleFormSubmit}
        >
            <div className="two-step__body">
                <label
                    htmlFor={`${dataQeId}-siteContactEmailLogin`}
                    className="screen-reader-text"
                >
                    Email Address
                </label>
                <input
                    className="two-step__input"
                    id={`${dataQeId}-siteContactEmailLogin`}
                    name="email"
                    type="text"
                    placeholder={inputPlaceholder}
                    autoComplete="email"
                    required
                    data-qe-id={`${dataQeId}-siteContactEmailLogin`}
                    onChange={event => setValue(event.target.value)}
                    ref={input}
                />
            </div>
            <div className="two-step__footer">
                <CtaButton
                    {...attributes}
                    buttonText={submitButtonText}
                    className="two-step__submit cta cta--regular cta--orange track-click"
                    dataQeId={`${dataQeId}-two-step`}
                    onSubmit={handleFormSubmit}
                    type="submit"
                    contentful_id={contentful_id}
                />
                <p className="two-step__caption">{twoStepCaption}</p>
            </div>
        </form>
    );
};

TwoStepSignupForm.propTypes = {
    inputPlaceholder: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    twoStepCaption: PropTypes.string.isRequired
};

TwoStepSignupForm.defaultProps = {
    dataQeId: 'rogue'
};

export default TwoStepSignupForm;
