import React from 'react';
import PropTypes from 'prop-types';
import TextBlock from '../text-block';
import './checkbox-text.scss';

const CheckboxTextWrapper = (props) => {
    const { checkboxTexts, json, ...attributes } = props,
        whiteChecks = "https://images.ctfassets.net/t21gix3kzulv/28zE589tROodcfUopNSrzG/c1c8719c9573f5b504c4aa016b0d0795/checkmark.svg",
        greenChecks = "https://static.ctctcdn.com/lp/images/standard/icons/checkmark.svg";

    return (
        <>
            {checkboxTexts.map((text, i) => {
                const {
                    textRichText,
                } = text;

                return (
                    <span className="checkbox-text" key={i}>
                        <img
                            className="checkbox-text__image"
                            width="14"
                            height="11"
                            src={attributes.section ? (attributes.section.background === 'blue' ? whiteChecks : greenChecks) : ''}
                            alt="A check mark"
                            loading="lazy"
                        />
                        {textRichText &&
                            <TextBlock
                                className="checkbox-text__text"
                                {...textRichText}
                                {...attributes}
                                dataQeId="checkbox"
                            />
                        }
                    </span>
                )
            })}
        </>
    );
};

CheckboxTextWrapper.propTypes = {
    checkboxText: PropTypes.shape({
        textRichText: PropTypes.shape({
            text: PropTypes.object.isRequired
        })
    })
};

export default CheckboxTextWrapper;
