import React from 'react';
import PropTypes from 'prop-types';

const Calculator = props => {
    const { width, height } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 18 21">
            <path
                d="M16.7143 0H1.2857C.5143 0 0 .5143 0 1.2857v18c0 .7714.5143 1.2857 1.2857 1.2857h15.4286c.7714 0 1.2857-.5143 1.2857-1.2857v-18C18 .5143 17.4857 0 16.7143 0zM5.1429 16.7143H2.5714v-2.5714H5.143v2.5714zm0-5.1429H2.5714V9H5.143v2.5714zm5.1428 5.1429H7.7143v-2.5714h2.5714v2.5714zm0-5.1429H7.7143V9h2.5714v2.5714zm5.1429 5.1429H12.857V9h2.5715v7.7143zm0-10.2857H2.5714V2.5714h12.8572v3.8572z"
                fill="#FFF"
                fillRule="nonzero"/>
        </svg>
    );
};

Calculator.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

Calculator.defaultProps = {
    width: 18,
    height: 21,
};

export default Calculator;
