import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';
import useModal from '../modal/useModal';
import PlayButton from '../../assets/svg/play-button';
import DownloadButton from '../../assets/svg/download';
import CalculatorButton from '../../assets/svg/calculator';
import ShoppingCart from '../../assets/svg/shopping-cart';
import { getAbsoluteUrl, getOmUrl, moveToAnchorId, KebabCase } from '../../assets/scripts/helpers';
import { loadScript } from '../../assets/scripts/helpers';
import './cta-button.scss';

/**
 * wraps an element based on a truthy condition
 * https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
 * should this live in herlpers.js? seems useful.
 */
const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

const CtaButton = props => {
    let hash,
        Tag = 'a';

    const {
        /*
            Destructure the garbage we don't accidentally
            want to pass to the DOM via {...attributes}
        */
        _id,
        _revision,
        _type,
        __typename,
        json,
        id,
        activeSection,
        builderPackage,
        callback,
        file,
        sectionCallback,

        /*
            This is garbage from preview
        */
        _createdAt,
        _updatedAt,
        clickTracking,
        nodeType,

        // Actual props we want
        tabindex,
        linkTreatment,
        buttonClass,
        buttonText,
        children,
        className,
        color,
        contentful_id,
        ctctLocalePref,
        dataQeId,
        eventActionText,
        icon,
        onClick,
        onSubmit,
        parent: {
            contentful_id:pid
        },
        section,
        target,
        size,
        image,
        imageAlt,
        modal,
        url,
        type,
        triggerChat,
        chatReference,
        pricing,
        planType,
        currencySymbol,
        ...attributes
    } = props,
    [href, setHref] = useState(url),
    [chat, setChat] = useState(false),
    chatRef = useRef(null);

    const {isShowing, toggle} = useModal();

    if (/button|submit/.test(type)) {
        Tag = 'button'
    }

    /*
        All contentful_id's are reduced to their first 3 characters.
        The contentful_id's are then concatenated like so:
        {section contentful_id}-{parent contentful_id}-{button contentful_id}
        uid = Unique ID

        This provides a static section > component > button specific ID
        which is usful for targeting elements in unit / functional testing
        as well as analytics.
    */
    const uid = `${section.contentful_id && section.contentful_id.substring(0,3)}-${pid && pid.substring(0,3)}${contentful_id && contentful_id !== undefined ? '-' + contentful_id.substring(0,3) : ''}`;

    if (url && url.includes('wistia')) {
        hash = url.split('/').pop();
    }

    const getClasses = () => {
        let classes = [];

        if (buttonClass) {
            classes.push(KebabCase(buttonClass));
        }

        if (className) {
            classes.push(className);
        }

        if (image) {
            classes.push(`cta--image`);
        }

        if (size) {
            classes.push(`cta cta--${size.toLowerCase()}`);
        }

        if (color) {
            classes.push(`cta--${color.toLowerCase()}`);
        }

        if (linkTreatment) {
            classes.push('cta--link');
        }

        if (icon && icon !== 'none') {
            classes.push(`cta--icon cta--icon__${icon.toLowerCase()}`)
        }

        if (classes.length === 0) {
            return null;
        }

        return classes.join(' ');
    };

    const onButtonClick = (event) => {

        if (chat && typeof window.liveagent === 'object') {
            event.preventDefault();
            window.liveagent.startChat(chatReference.liveAgentFormId);
        }

        if (url && (url !== undefined) && (url.includes('#'))) {
            moveToAnchorId(url)
        }

        if (modal) {
            event.preventDefault();
            toggle();
        }

        if (typeof onClick === 'function') {
            onClick(event);
        }
    };

    const handleScriptSuccess = () => {

        const inProduction =
                window.location.hostname === 'www.retentionscience.com',
            liveagent = window.liveagent || {};

        let endpoint = chatReference.uatEndpoint,
            orgID = chatReference.uatOrganizationId,
            deploymentID = chatReference.uatDeploymentId,
            soid;

        if (inProduction) {
            endpoint = chatReference.productionEndpoint;
            orgID = chatReference.productionOrganizationId;
            deploymentID = chatReference.productionDeploymentId;
        }

        if (window.utag_data) {
            soid = window.utag_data.souid;
        }

        if (!window._laq) {
            window._laq = [];
        }

        window._laq.push(() => {
            liveagent.showWhenOnline(chatReference.liveAgentFormId, chatRef.current);

            liveagent
                .addCustomDetail('Chat URL', window.location.href)
                .saveToTranscript('Chat_Initiated_From__c');

            if (soid) {
                liveagent.addCustomDetail('Lead SOID', soid);
                liveagent
                    .findOrCreate('Lead')
                    .map('SOID__c', 'Lead SOID', true, true, false)
                    .saveToTranscript('LeadId')
                    .showOnCreate();
            }
        });

        liveagent.init(endpoint, deploymentID, orgID);
    }

    useEffect(() => {
        setHref(getAbsoluteUrl(url));

        if (hash) {
            loadScript('https://fast.wistia.com/assets/external/E-v1.js');
        }

        if (triggerChat) {
            setChat(true);

            const url = 'https://c.la4-c2cs-dfw.salesforceliveagent.com/content/g/js/42.0/deployment.js';

            if (typeof(window.liveagent) === 'object') {
                delete window.liveagent;
                delete window.liveAgentDeployment;
                delete window._laq;

                loadScript(url, handleScriptSuccess);
            } else {
                loadScript(url, handleScriptSuccess);
            }
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ConditionalWrapper
            condition={hash}
            wrapper={children => (
                <span
                    className={`wistia_embed wistia_async_${hash} popover=true popoverContent=link preload=none cta__video-wrapper id=${hash}`}
                >
                    {children}
                </span>
            )}
        >
            <Tag
                ref={chatRef}
                className={getClasses()}
                tabIndex={`${tabindex ? tabindex : 0}`}
                data-qe-id={`${dataQeId}-cta-${uid}`}
                data-om-config={`${dataQeId}-cta, ${eventActionText || buttonText} ${uid} ${getOmUrl(url)}`}
                href={type !== 'button' ? href : null}
                target={target &&
                    (target.toLowerCase() === 'new window' || target === '_blank') ? '_blank' : null }
                type={type}
                onClick={onButtonClick}
                onSubmit={onSubmit}
                style={chat ? {display: 'none'} : null}
                {...attributes}
            >
                {icon && (icon !== 'none') && (
                    icon === 'play-button' ?
                    <PlayButton width={29} height={29} />
                    : (icon === 'download-button') ?
                    <DownloadButton width={22} height={21} />
                    : (icon === 'calculator') ?
                    <CalculatorButton width={size === "Regular" ? 18 : 14} height={size === "Regular" ? 21 : 16} />
                    : (icon === 'shopping-cart') ?
                    <ShoppingCart width={28} height={27} /> :
                    null
                )}
                {(image && image !== undefined) ? (
                    <img src={image.file.url} alt={imageAlt} />
                ) : (
                    <>
                        {buttonText}
                        {children}
                        {icon && (icon === 'right-caret') && <span className='right-caret'></span>}
                    </>
                )}
            </Tag>
            {modal &&
                <Modal {...modal} isShowing={isShowing} toggle={toggle} planType={planType} pricing={pricing} currencySymbol={currencySymbol} />
            }
        </ConditionalWrapper>
    );
};

CtaButton.propTypes = {
    buttonText: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    parent: PropTypes.object,
    image: PropTypes.object,
    imageAlt: PropTypes.string,
    section: PropTypes.object,
    size: PropTypes.string,
    target: PropTypes.string,
    url: PropTypes.string,
    triggerChat: PropTypes.bool
};

CtaButton.defaultProps = {
    contentful_id: 'rogue',
    dataQeId: 'rogue',
    parent: {
        contentful_id: 'rogue'
    },
    section: {
        contentful_id: 'rogue'
    },
    triggerChat: false
};

export default CtaButton;
