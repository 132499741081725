import React from 'react';
import PropTypes from 'prop-types';

const Image = props => {

    const {
        altText,
        desktopImage: {
            file: { url: desktopUrl },
        },
        tabletImage: {
            file: { url: tabletUrl },
        },
        mobileImage: {
            file: { url: mobileUrl }
        },
        lazyload
    } = props,
    classes = props.className || '';

    return (
        <picture>
            <source srcSet={mobileUrl} media="(max-width: 767px)" />
            <source srcSet={tabletUrl} media="(max-width: 1199px)" />
            <img
                src={desktopUrl}
                alt={altText}
                className={classes}
                loading={lazyload ? 'lazy' : null}
            />
        </picture>
    );
};

Image.propTypes = {
    mobileImage: PropTypes.object.isRequired,
    tabletImage: PropTypes.object.isRequired,
    desktopImage: PropTypes.object.isRequired,
    altText: PropTypes.string.isRequired,
    lazyload: PropTypes.bool
}

Image.defaultProps= {
  altText: "Retention Science",
  lazyload: true
}

export default Image;
