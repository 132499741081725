import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import TextBlock from '../text-block';

const ConditionalWrapper = props => {

    const {
        contentful_id,
        id,
        conditionalKey,
        conditionalType,
        components,
        ...attributes
    } = props,
    parent = {
        contentful_id,
        id
    },
    cookies = new Cookies(),
    [conditionalStateValue, setConditionalStateValue] = useState('');

    const getCookieValue = () => {
        const conditions = conditionalKey.split(',');
        let cookieValue = cookies.get(conditions[0]);

        // This only goes one level deep but could potentially drill with a for loop in the future
        if (cookieValue && conditions.length > 1) {
            cookieValue = cookieValue.split(conditions[1].trim())[1];
        }

        if (cookieValue !== undefined) {
            setConditionalStateValue(cookieValue);
        }
    }

    useEffect(() => {
        switch(conditionalType.toLowerCase()){
            case 'cookie':
                    getCookieValue();
                break;
            default:
                return
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="conditional-wrapper">
            {components && components.map((item, i) => {
                const {
                    component,
                    conditionalValue,
                    contentful_id
                } = item;

                return (
                    <div key={i}>
                        {conditionalStateValue.includes(conditionalValue) &&
                            <TextBlock
                                {...component}
                                {...attributes}
                                {...parent}
                                contentful_id={contentful_id}
                                dataQeId="conditional-component"
                            />
                        }
                    </div>
                );
            })}
        </div>
    );
};

ConditionalWrapper.propTypes = {
    components: PropTypes.array
}

ConditionalWrapper.defaultProps= {}

export default ConditionalWrapper;
