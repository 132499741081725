import React from 'react';
import PropTypes from 'prop-types';
import CtaButton from '../cta-button';
import './cta-button-wrapper.scss';

const CtaButtonWrapper = props => {
    const {
        ctaButtons,
        contentful_id,
        id,
        ...attributes
    } = props,
    parent = {
        contentful_id,
        id
    };

    return (
        <div className="cta-button--wrapper">
            {ctaButtons.map((button, i) => (
                <CtaButton
                    key={`cta-button-${i}`}
                    {...button}
                    {...attributes}
                    parent={parent}
                    dataQeId="cta-button-wrapper"
                />
            ))}
        </div>
    );
};

CtaButtonWrapper.propTypes = {
    ctaButtons: PropTypes.array
};

export default CtaButtonWrapper;
