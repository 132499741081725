import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { KebabCase, ValidateField } from '../../assets/scripts/helpers';
import '../input/input.scss';

const Textarea = props => {
    const {
        dataQeId,
        textareaName:name,
        textareaLabel:label,
        textareaRequired:required,
        placeholderText
    } = props;

    const [textarea, setTextarea] = useState({
        value: '',
        focus: false,
        error: {
            status: false,
            message: ''
        }
    }),
    {
        value,
        focus,
        error: {
            status,
            message
        }
    } = textarea;

    const fieldId = name && KebabCase(name);

    const fieldsetClass = (status ? 'error' : '') + (focus ? ' focus' : '');
    const inputClass = value || placeholderText ? 'push' : '';
    const messageClass = status ? 'message--error' : '';

    return (
        <div className="field">
            <fieldset className={`textarea ${fieldsetClass}`}>
                <textarea
                    id={fieldId}
                    className={inputClass}
                    data-qe-id={`${dataQeId}-${fieldId}`}
                    name={name}
                    required={required}
                    placeholder={placeholderText}
                    value={value}
                    onFocus={e => setTextarea({...textarea, focus: true})}
                    onBlur={e => ValidateField(e, (v) => {
                        setTextarea({
                            ...textarea,
                            focus: false,
                            error: {
                                status: v.status,
                                message: v.message
                            }
                        })
                    })}
                    onChange={e => setTextarea({...textarea, value: e.target.value})}
                />
                <label htmlFor={fieldId}>{label}</label>
            </fieldset>
            <div className={`message ${messageClass}`}>
                {required &&
                    <React.Fragment>
                        <sup>*</sup>Required
                    </React.Fragment>
                }
                {message &&
                    <React.Fragment>: {message}</React.Fragment>
                }
            </div>
        </div>
    );
};

Textarea.propTypes = {
    dataQeId: PropTypes.string.isRequired,
    textareaLabel: PropTypes.string.isRequired,
    textareaName: PropTypes.string.isRequired,
    textareaRequired: PropTypes.array,
};

export default Textarea;
