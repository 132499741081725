import React from 'react';
import PropTypes from 'prop-types';
import { KebabCase } from '../../assets/scripts/helpers';
import './section.scss';


const Section = props => {
    const {
        sectionName,
        backgroundColor,
        sectionTopSpacing,
        sectionBottomSpacing,
        children
    } = props;

    const sectionId = sectionName && KebabCase(sectionName);

    const classes = () => {
        const top = sectionTopSpacing.split('px')[0],
            bottom = sectionBottomSpacing.split('px')[0];

        return (top === '0' ? '' : `padding-top${top} `)
            + (bottom === '0' ? '' : `padding-bottom${bottom} `)
            + (backgroundColor !== 'none' ? `background background--${KebabCase(backgroundColor)}` : '');
    };

    return (
        <section
            className={classes()}
            id={sectionId}
        >
            <div
                className={`container container--${sectionId}`}
            >
                {children}
            </div>
        </section>
    )
}

Section.propTypes = {
    backgroundColor: PropTypes.string,
    sectionName: PropTypes.string,
    sectionTopSpacing: PropTypes.string,
    sectionBottomSpacing: PropTypes.string
};

Section.defaultProps = {
    backgroundColor: 'none'
};

export default Section;
