import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SubFeaturesBlock from '../sub-features-block';
import CtaButton from '../cta-button';
import LinkList from './link-list';

const BreadcrumbDropdown = dropdown => {

    const [subFeatIsOpen, setSubFeatIsOpen] = useState(false),
        [linkListIsOpen, setListLinkIsOpen] = useState(false),
        node = useRef();

    const handleClickOutside = () => {
        setSubFeatIsOpen(false);
        setListLinkIsOpen(false);
    };

    const {
        dataQeId,
        ...attributes
    } = dropdown;

    useEffect(() => {
        const onEscKeyDown = (e) => {
            const code = e.keyCode;

            if (code === 27) {
                if (subFeatIsOpen || setListLinkIsOpen) {
                    setSubFeatIsOpen(false);
                    setListLinkIsOpen(false);
                }
            }
        };

        if (window) {
            window.addEventListener('keydown', onEscKeyDown);
        }

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = e => {

        if (node.current.contains(e.target) || e.target === node.current.previousSibling) {
            return;
        }

        handleClickOutside();
    }

    const setDropdownBlockWidth = () => {
        let prop;

        dropdown.hasOwnProperty('subFeatures') ? prop = 'subFeatures' : prop = 'links';

        if (dropdown.hasOwnProperty(prop)) {
            if (dropdown[prop].length === 1) {
                return 'solo'
            } else if (dropdown[prop].length % 3 === 0) {
                return 'thirds'
            } else if (dropdown[prop].length === 2) {
                return 'halves'
            } else {
                return
            }
        }
    }

    return (
        <div className="breadcrumbs__menu">
            <CtaButton
                parent={attributes.parent}
                section={attributes.section}
                dataQeId="breadcrumb-toggle"
                eventActionText="Breadcrumb Dropdown Toggle"
                type="button"
                className={`breadcrumbs__toggle breadcrumbs__toggle--open-${dropdown.hasOwnProperty('subFeatures') ? subFeatIsOpen : linkListIsOpen}`}
                onClick={() => dropdown.hasOwnProperty('subFeatures') ? setSubFeatIsOpen(!subFeatIsOpen) : setListLinkIsOpen(!linkListIsOpen)}
            >
                <svg width="7" height="5" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#1856ED" fillRule="nonzero" d="M3.503 4.71L6.71 1.502 5.498.29 3.503 2.285 1.508.29.296 1.502z"/>
                </svg>
                <span className="screen-reader-text">{`Additional menu options for ${attributes.headline}`}</span>
            </CtaButton>
            <div ref={node} className={`breadcrumbs__features breadcrumbs__features--open-${dropdown.hasOwnProperty('subFeatures') ? subFeatIsOpen : linkListIsOpen} ${setDropdownBlockWidth()}`}>
                {dropdown.hasOwnProperty('subFeatures') ? <SubFeaturesBlock {...dropdown} {...attributes}/> : <LinkList {...dropdown}/> }
            </div>
        </div>
    )
};

BreadcrumbDropdown.propTypes = {
    dropdown: PropTypes.object,
    dataQeId: PropTypes.string,
};

BreadcrumbDropdown.defaultProps = {
    dataQeId: 'rogue',
};

export default BreadcrumbDropdown;
