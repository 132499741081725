import React from 'react';
import PropTypes from 'prop-types';
import { KebabCase } from '../../assets/scripts/helpers';
import CtaButton from '../cta-button';
import CtaButtonWrapper from '../cta-button-wrapper';
import Breadcrumbs from '../breadcrumbs';
import Lank from '../link';
import './centered-cta.scss';
import TwoStepSignupForm from '../two-step-signup-form';
import TextBlock from '../text-block';
import Html from '../html';

class CenteredCta extends React.Component {

    render() {
        const {
            actAsHero,
            alignLeft,
            breadcrumbs,
            centeredCtaHtml,
            contentful_id,
            contentSize: isSmallText,
            headline,
            id,
            sectionIntro,
            stepSignUpFormOrButtons,
            subContent,
            textAdvertismentWithRule,
            textAfterCta,
            ...attributes
        } = this.props,
        classes = this.props.className || '';

        const parent = {
            contentful_id,
            id
        };

        return (
            <div
                className={`centered-cta ${classes}${attributes.section ? ` centered-cta--${KebabCase(attributes.section.background)}` : ''}${actAsHero ? ` centered-cta--hero` : alignLeft ? ` centered-cta--hero--left` : ''}${breadcrumbs ? ` centered-cta--hero--breadcrumbs` : ''}`}
                data-expand="-20"
            >
                {breadcrumbs && <Breadcrumbs {...breadcrumbs} {...attributes} dataQeId="hero" />}

                {sectionIntro && (
                    <h3>{sectionIntro}</h3>
                )}

                {headline && (
                    isSmallText
                        ? <h4>{headline}</h4>
                        : <p className="type-callout">{headline}</p>
                )}

                {subContent && (
                    <div className="centered-cta__supporting-text">
                        <TextBlock {...subContent} {...attributes} dataQeId="centered-cta" />
                    </div>
                )}

                {stepSignUpFormOrButtons && (
                    stepSignUpFormOrButtons.map((formOrButton, i) => {

                        return (
                            <React.Fragment key={i}>
                                {formOrButton.hasOwnProperty('ctaButtons') &&
                                    <div className="centered-cta__centered-button">
                                        <CtaButtonWrapper
                                            {...formOrButton}
                                            {...attributes}
                                            parent={parent}
                                            dataQeId="centered-cta"
                                        />
                                    </div>
                                }
                                {formOrButton.hasOwnProperty('buttonText') &&
                                    <div className="centered-cta__centered-button">
                                        <CtaButton
                                            {...formOrButton}
                                            {...attributes}
                                            parent={parent}
                                            dataQeId="centered-cta"
                                        />
                                    </div>
                                }
                                {formOrButton.hasOwnProperty('twoStepCaption') &&
                                    <TwoStepSignupForm
                                        {...formOrButton}
                                        {...attributes}
                                        parent={parent}
                                        dataQeId="centered-cta"
                                    />
                                }
                            </React.Fragment>
                        )
                    })
                )}

                {textAfterCta && (
                    <div className="centered-cta__supporting-text">
                        <TextBlock {...textAfterCta} {...attributes} contentful_id={contentful_id} dataQeId="centered-cta" />
                    </div>
                )}

                {textAdvertismentWithRule && (
                    <div className="centered-cta__bottom-section" data-expand="-20">
                        <h4>{textAdvertismentWithRule.heading}</h4>
                        <p>{textAdvertismentWithRule.subHeading}</p>
                        {textAdvertismentWithRule.link && textAdvertismentWithRule.link.map((link, i) => {
                            const {
                                contentful_id,
                                linkText,
                                url,
                            } = link;
                            return (
                                <Lank
                                    dataQeId="centered-cta"
                                    key={i}
                                    link={{contentful_id}}
                                    parent={parent}
                                    url={url}
                                    {...attributes}
                                >
                                    {linkText}
                                    <svg width="5" height="8">
                                        <path
                                            d="M1.174 7.5L.5 6.853 3.16 4.3.5 1.746l.674-.646L4.5 4.292l-.009.008.009.008z"
                                            fill="#1856ed"
                                            stroke="#1856ed"
                                        />
                                    </svg>
                                </Lank>
                            )
                        })}
                    </div>
                )}
                {centeredCtaHtml &&
                    centeredCtaHtml.map((html, i) => {
                        return <Html {...html} key={i} />
                    })
                }
            </div>
        );
    }
}

CenteredCta.propTypes = {
    actAsHero: PropTypes.bool,
    breadcrumbs: PropTypes.object,
    contentful_id: PropTypes.string,
    contentSize: PropTypes.bool,
    headline: PropTypes.string,
    id: PropTypes.string,
    sectionIntro: PropTypes.string,
    stepSignUpFormOrButtons: PropTypes.array,
    subContent: PropTypes.object,
    textAdvertismentWithRule: PropTypes.object,
    textAfterCta: PropTypes.object,
};

export default CenteredCta;
