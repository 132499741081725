export const contactData = {
    500: 195,
    2500: 195,
    5000: 195,
    10000: 245,
    15000: 295,
    25000: 355,
    30000: 425,
    35000: 445,
    50000: 475,
    55000: 525,
    60000: 545,
    65000: 565,
    75000: 585,
    80000: 595,
    85000: 615,
    90000: 625,
    100000: 640,
    105000: 655,
    110000: 675,
    115000: 695,
    120000: 715,
    125000: 735,
    130000: 755,
    135000: 795,
    150000: 835,
    160000: 955,
    170000: 995,
    180000: 1035,
    200000: 1059
};
