import React from 'react';
import PropTypes from 'prop-types';
import TextBlock from '../text-block';
import Image from '../image';
import Video from '../video-block';
import './two-column-block.scss';

const TwoColumnBlock = (props) => {
    const {
        imageSide: imageOnRight,
        imageSize: fullWidthImage,
        introText,
        headline,
        logo,
        textRichText,
        imageOrVideo,
        ...attributes
    } = props;

    function displayMedia(props) {
        return(
            <div className="two-column-block__media__wrapper" key="media" >
                <div className="two-column-block__media">
                    {imageOrVideo && imageOrVideo.hasOwnProperty('wistiaShareLink') ? <Video {...imageOrVideo}/> : <Image {...imageOrVideo} />}
                </div>
            </div>
        )
    };

    function displayContent(props) {
        return(
            <div className="two-column-block__content__wrapper" key="content" >
                <div className="two-column-block__content">
                    {headline && <h2 className="two-column-block__content-headline">{headline}</h2>}
                    {introText && <h3 className="two-column-block__content-intro">{introText}</h3>}
                    {logo &&
                                    logo.logo.file.url &&
                                    logo.alternativeText && (
                                            <img
                                                src={logo.logo.file.url}
                                                className="two-column-block__content-logo"
                                                alt={logo.alternativeText}
                                                loading="lazy"
                                            />
                                    )}
                    {textRichText && <TextBlock {...textRichText} {...attributes} dataQeId="two-column" /> }
                </div>
            </div>
        )
    };

    return (
        <section className={`two-column-block${fullWidthImage ? ` full-width-image` : ``}${!imageOnRight ? ` image-left` : ``}`}>
            <div className="two-column-block__container">
                {imageOnRight ?
                    [displayContent(), displayMedia()] :
                    [displayMedia(), displayContent()]
                }
            </div>
        </section>
    );
}

TwoColumnBlock.propTypes = {
    imageSide: PropTypes.bool,
    imageSize: PropTypes.bool,
    imageOrVideo: PropTypes.object.isRequired,
    introText: PropTypes.string,
    logo: PropTypes.object,
    textRichText: PropTypes.object
}

export default TwoColumnBlock;
