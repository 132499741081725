import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import TextBlock from '../text-block';
import CTAButton from '../cta-button';
import PricingTable from '../pricing-table';
import RoiCalculator from '../roi-calculator';
import './modal.scss';

const Modal = props => {

    const {
        id,
        contentful_id,
        classes,
        isShowing,
        toggle,
        headline,
        headlineColor,
        content,
        introContent,
        bottomContent,
        currencySymbol,
        planType,
        pricing,
        pricingTable,
        ...attributes
    } = props,
    parent = {
        contentful_id,
        id
    },
    modal = useRef(null);

    let classNames = classes ? classes : ''

    useEffect(() => {
        const event = document.createEvent('Event');

        event.initEvent('modalOpened', true, true);

        const onKeyDown = (e) => {
            const code = e.keyCode;

            switch (code) {
                case 27: // Esc
                    toggle();
                    break;
                default:
            }
        }

        const onMouseDown = e => {
            if (modal.current === null || modal.current.contains(e.target)) {
                return;
            }

            toggle();
        }

        if (isShowing && typeof window !== 'undefined') {
            document.addEventListener('mousedown', onMouseDown);
            window.addEventListener('keydown', onKeyDown);

            document.dispatchEvent(event);
        }

        if (isShowing) {
            document.documentElement.classList.add('no-scroll');
        } else {
            document.documentElement.classList.remove('no-scroll');
        }

        return () => {
            document.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('keydown', onKeyDown);
        }
    }, [isShowing]); // eslint-disable-line react-hooks/exhaustive-deps

    const fireUtag = () => {
        if (typeof window !== 'undefined' && window.utag) {
            window.utag.link({
                tealium_event:'link',
                roicalc_event:'close-modal'
            });
        }
    }

    return (
        <React.Fragment>
            {typeof document !== 'undefined' && ReactDOM.createPortal(
                <div className={`modal${isShowing ? ' open' : ''} ${classNames}`}>
                    <div className="modal__wrapper" aria-modal aria-hidden={isShowing ? false : true} tabIndex={-1} role="dialog">
                        <div
                            className={`modal__content${introContent ? ' roi-modal' : ''}${pricingTable ? ' modal__pricing-table' : ''}`}
                            ref={modal}
                        >
                            <CTAButton
                                className="modal__close"
                                contentful_id="modal-close"
                                aria-label="Close"
                                dataQeId="modal-close"
                                data-dismiss="modal"
                                eventActionText="Close modal"
                                onClick={() => {
                                    introContent && fireUtag()
                                    toggle()
                                }}
                                parent={contentful_id ? parent : {contentful_id : 'roi-calculator-modal'}}
                                section={{contentful_id: contentful_id ? contentful_id : 'roi-calculator-modal'}}
                                type="button"
                            >
                                <svg width="19" height="19">
                                    <path
                                        d="M17.0782 1.5218L1.5218 17.0782m15.5564 0L1.5218 1.5218"
                                        stroke="#757677"
                                        strokeWidth="3.3"
                                        fill="none"
                                        fillRule="evenodd"
                                    />
                                </svg>
                                <span className="screen-reader-text">Close overlay</span>
                            </CTAButton>
                            {headline && <h4 className={`modal__headline modal__headline--${headlineColor}`}>{headline}</h4>}
                            {pricingTable && <PricingTable pricing={pricing} planType={planType} currencySymbol={currencySymbol} />}
                            {content && <TextBlock {...attributes} {...content} contentful_id={contentful_id} dataQeId="modal-text-block" />}
                            {introContent && <RoiCalculator {...attributes} introContent={introContent} bottomContent={bottomContent} dataQeId="ROI-Calculator" />}
                        </div>
                    </div>
                </div>, document.body
            )}
        </React.Fragment>
    )
}

Modal.propTypes = {
    headline: PropTypes.string,
    headlineColor: PropTypes.string,
    content: PropTypes.object
};

export default Modal;
