import React from 'react';
import PropTypes from 'prop-types';

const PlayButton = props => {
    const { width, height } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 29 29">
            <g fill="none" fillRule="evenodd">
                <circle
                    stroke="#FFF"
                    strokeWidth=".8"
                    cx="14.4"
                    cy="14.4"
                    r="14"
                />
                <path
                    d="M12.421 9.09a1.043 1.043 0 00-.621.954v8.342a1.044 1.044 0 001.745.773l6.713-4.172a1.043 1.043 0 000-1.545l-6.713-4.171a1.044 1.044 0 00-1.124-.182z"
                    fill="#FFF"
                />
            </g>
        </svg>
    );
};

PlayButton.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

PlayButton.defaultProps = {
    width: 29,
    height: 29,
};

export default PlayButton;
