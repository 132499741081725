import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CtaButton from '../cta-button';
import TinyMCERichTextBlock from '../tinymce-rich-text-block';
import Cookies from 'universal-cookie';
import { KebabCase } from '../../assets/scripts/helpers';
import './banner.scss';

const Banner = props => {
    const {
        backgroundColor,
        contentful_id,
        content,
        displayCloseButton,
        id,
        showBannerOnlyOnce,
        heightCallback,
    } = props,
    [open, setOpen] = useState(true),
    banner = useRef();

    const parent = {
            contentful_id,
            id
        },
        section = {contentful_id: contentful_id};

    useEffect(() => {
        const cookies = new Cookies();
        let running = false,
            count = 0,
            url;

        if (window !== 'undefined') {

            url = window.location.pathname;
            const topBannerCookie = cookies.get(KebabCase('top-banner-cookie' + url));

            const handleWindowResize = () => {
                if (heightCallback && typeof heightCallback === 'function') {
                    if (open && banner.current) {
                        heightCallback(banner.current.clientHeight);
                    }
                }
            };

            // if show banner once is false, show banner every time
            // if show banner once is true & cookie is true, hide banner
            // if show banner once is true & cookie is false, show banner
            if (topBannerCookie) {
                (showBannerOnlyOnce && topBannerCookie) ? setOpen(false) : setOpen(true)
            } else {
                handleWindowResize();
                showBannerOnlyOnce ? cookies.set(KebabCase('top-banner-cookie' + url), true) : cookies.set(KebabCase('top-banner-cookie' + url), false);
            }

            handleWindowResize();

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        }

        // fire impression tracking IF banner is on the screen
        // if it's not, check again & only check a max of 5 times
        const checkRef = () => {
            const topBanner = banner.current.querySelector('.top-banner');
            running = true;

            if (topBanner) {
                if (typeof window !== 'undefined' && window.utag) {
                   window.utag.link({
                       tealium_event: 'impression',
                       ga_event_category: 'impression',
                       ga_event_action: 'top-banner>init-banner',
                       ga_event_label:
                           window.location.pathname +
                           ' top-banner>init-banner',
                       ga_event_link_name: 'init-banner',
                       ga_nonInteraction: true,
                   });
               }
            } else {
                if (count < 5) {
                    setTimeout(checkRef, 1000);
                    count++;
                }
            }
        };

        if (!running) {
            checkRef();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fireUtag = () => {
        if (typeof window !== 'undefined' && window.utag) {
            window.utag.link({
                tealium_event: 'click',
                ga_event_category: 'click',
                ga_event_action: 'top-banner>close-banner',
                ga_event_label: window.location.pathname + ' top-banner-close-button',
                ga_event_link_name: 'top-banner-close'
            });
        }
    }

    const sendCloseEvent = () => {
        if (typeof window !== 'undefined' && window.utag) {
            fireUtag('close');
        }

        heightCallback(0);
        setOpen(false);
    };

    return (
        <React.Fragment>
            {open &&
                <div
                    className={`top-banner top-banner--${KebabCase(backgroundColor)}${open ? ' open' : ''}${displayCloseButton ? ' sticky': ''}`}
                    ref={banner}
                >
                    <TinyMCERichTextBlock
                        content={content}
                        dataQeId="top-banner"
                        contentful_id={contentful_id}
                        section={section}
                        id={id}
                    />
                    {displayCloseButton &&
                        <CtaButton
                            className="top-banner__close"
                            contentful_id="tbc"
                            dataQeId="top-banner-close"
                            eventActionText="Close banner"
                            onClick={() => sendCloseEvent()}
                            parent={parent}
                            section={section}
                            type="button"
                        >
                            <svg width="33" height="33">
                                <g
                                    transform="translate(1 1)"
                                    stroke={backgroundColor === 'orange' ? '#333' : '#fff'}
                                    fill="none"
                                    fillRule="evenodd"
                                >
                                    <path
                                        d="M19.6205 12.1356l-7.485 7.485m7.485 0l-7.485-7.485"
                                        strokeWidth="3"
                                        strokeLinecap="square"
                                    />
                                    <circle cx="15.5" cy="15.5" r="15.5"/>
                                </g>
                                </svg>
                            <span className="screen-reader-text">Close overlay</span>
                        </CtaButton>
                    }
                </div>
            }
        </React.Fragment>
    )
};

Banner.propTypes = {
    backgroundColor: PropTypes.string,
    content: PropTypes.object,
    displayCloseButton: PropTypes.bool,
    showBannerOnlyOnce: PropTypes.bool,
};

export default Banner;
