export const pricingModel = {
    "list_size_tiers": [
        {
            "list_size": "0-500",
            "min": 0,
            "max": 500,
            "package_prices": {
                "USD": {
                    "email": 20,
                    "email-plus": 45
                },
                "GBP": {
                    "email": 15,
                    "email-plus": 30
                },
                "EUR": {
                    "email": 18,
                    "email-plus": 40
                },
                "MXN": {
                    "email": 365,
                    "email-plus": 821
                },
                "AUD": {
                    "email": 26,
                    "email-plus": 58
                },
                "CAD": {
                    "email": 26,
                    "email-plus": 57
                },
                "INR": {
                    "email": 1289,
                    "email-plus": 2900
                }
            }
        },
        {
            "list_size": "501-2,500",
            "min": 501,
            "max": 2500,
            "package_prices": {
                "USD": {
                    "email": 45,
                    "email-plus": 70
                },
                "GBP": {
                    "email": 30,
                    "email-plus": 45,
                },
                "EUR": {
                    "email": 40,
                    "email-plus": 61
                },
                "MXN": {
                    "email": 821,
                    "email-plus": 1277
                },
                "AUD": {
                    "email": 58,
                    "email-plus": 90
                },
                "CAD": {
                    "email": 58,
                    "email-plus": 88
                },
                "INR": {
                    "email": 2900,
                    "email-plus": 4511
                }
            }
        },
        {
            "list_size": "2,501-5,000",
            "min": 2501,
            "max": 5000,
            "package_prices": {
                "USD": {
                    "email": 65,
                    "email-plus": 95
                },
                "GBP": {
                    "email": 45,
                    "email-plus": 60
                },
                "EUR": {
                    "email": 57,
                    "email-plus": 83
                },
                "MXN": {
                    "email": 1186,
                    "email-plus": 1733
                },
                "AUD": {
                    "email": 84,
                    "email-plus": 122
                },
                "CAD": {
                    "email": 84,
                    "email-plus": 119
                },
                "INR": {
                    "email": 4189,
                    "email-plus": 6122
                }
            }
        },
        {
            "list_size": "5,001-10,000",
            "min": 5001,
            "max": 10000,
            "package_prices": {
                "USD": {
                    "email": 95,
                    "email-plus": 125
                },
                "GBP": {
                    "email": 65,
                    "email-plus": 85
                },
                "EUR": {
                    "email": 83,
                    "email-plus": 109
                },
                "MXN": {
                    "email": 1733,
                    "email-plus": 2280
                },
                "AUD": {
                    "email": 122,
                    "email-plus": 161
                },
                "CAD": {
                    "email": 122,
                    "email-plus": 157
                },
                "INR": {
                    "email": 6122,
                    "email-plus": 8055
                }
            }
        },
        {
            "list_size": "10,001-15,000",
            "min": 10001,
            "max": 15000,
            "package_prices": {
                "USD": {
                    "email":195 ,
                    "email-plus": 195
                },
                "GBP": {
                    "email": 120 ,
                    "email-plus": 120
                },
                "EUR": {
                    "email": 170,
                    "email-plus": 170
                },
                "MXN": {
                    "email": 3557,
                    "email-plus": 3557
                },
                "AUD": {
                    "email": 250,
                    "email-plus": 250
                },
                "CAD": {
                    "email": 250,
                    "email-plus": 245
                },
                "INR": {
                    "email": 12566,
                    "email-plus": 12566
                }
            }
        },
        {
            "list_size": "15,001-25,000",
            "min": 15001,
            "max": 25000,
            "package_prices": {
                "USD": {
                    "email": 225 ,
                    "email-plus": 225
                },
                "GBP": {
                    "email": 145 ,
                    "email-plus": 145
                },
                "EUR": {
                    "email": 196,
                    "email-plus": 196
                },
                "MXN": {
                    "email": 4104,
                    "email-plus": 4104
                },
                "AUD": {
                    "email": 289,
                    "email-plus": 289
                },
                "CAD": {
                    "email": 289,
                    "email-plus": 282
                },
                "INR": {
                    "email": 14499,
                    "email-plus": 14499
                }
            }
        },
        {
            "list_size": "25,001-30,000",
            "min": 25001,
            "max": 30000,
            "package_prices": {
                "USD": {
                    "email": 295 ,
                    "email-plus": 295
                },
                "GBP": {
                    "email": 185 ,
                    "email-plus": 185
                },
                "EUR": {
                    "email": 257,
                    "email-plus": 257
                },
                "MXN": {
                    "email": 5381,
                    "email-plus": 5381
                },
                "AUD": {
                    "email": 378,
                    "email-plus": 378
                },
                "CAD": {
                    "email": 378,
                    "email-plus": 370
                },
                "INR": {
                    "email": 19010,
                    "email-plus": 19010
                }
            }
        },
        {
            "list_size": "30,001-35,000",
            "min": 30001,
            "max": 35000,
            "package_prices": {
                "USD": {
                    "email": 315,
                    "email-plus": 315
                },
                "GBP": {
                    "email": 200,
                    "email-plus": 200
                },
                "EUR": {
                    "email": 275,
                    "email-plus": 275
                },
                "MXN": {
                    "email": 5746,
                    "email-plus": 5746
                },
                "AUD": {
                    "email": 404,
                    "email-plus": 404
                },
                "CAD": {
                    "email": 404,
                    "email-plus": 395
                },
                "INR": {
                    "email": 20298,
                    "email-plus": 20298
                }
            }
        },
        {
            "list_size": "35,001-50,000",
            "min": 35001,
            "max": 50000,
            "package_prices": {
                "USD": {
                    "email": 335 ,
                    "email-plus": 335
                },
                "GBP": {
                    "email": 215 ,
                    "email-plus": 215
                },
                "EUR": {
                    "email": 292,
                    "email-plus": 292
                },
                "MXN": {
                    "email": 6111,
                    "email-plus": 6111
                },
                "AUD": {
                    "email": 430,
                    "email-plus": 430
                },
                "CAD": {
                    "email": 430,
                    "email-plus": 420
                },
                "INR": {
                    "email": 21587,
                    "email-plus": 21587
                }
            }
        }
    ]
};
