import React from 'react';
import PropTypes from 'prop-types';
import './loading.scss'

const Loading = props => {
    const { 
        size
    } = props;

    return (
        <svg className={`loading--${size}`} viewBox="0 0 50 50">
            <circle 
                className="background"
                r="20" 
                cx="25" 
                cy="25" 
                fill="none"
                strokeWidth="10"
            />
            <circle 
                r="20" 
                cx="25" 
                cy="25" 
                fill="none" 
                stroke="#1856ed" 
                strokeWidth="10"
                strokeLinecap="round"
                className="path"
            />
        </svg>
    );
};

Loading.propTypes = {
    size: PropTypes.string,
};

Loading.defaultProps = {
    size: 'small',
};

export default Loading;
