import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getRelativeUrl, getAbsoluteUrl, getOmUrl, moveToAnchorId } from '../../assets/scripts/helpers';
import { Link as GatsbyLink } from 'gatsby';
import Modal from '../modal';
import useModal from '../modal/useModal';

const Link = props => {

    const {
        tabindex,
        ariaLabel,
        children,
        className,
        currencySymbol,
        contentful_id,
        dataQeId,
        eventActionText,
        link,
        onClick,
        itemProp,
        modal,
        planType,
        pricing,
        title,
        parent: {
            contentful_id:pid
        },
        rel,
        section: {
            contentful_id:sid
        },
        target,
        text,
        url
    } = props,
    [gatsbyLinkUrl, setGatsbyLinkUrl] = useState(url),
    [href, setHref] = useState(url);
    let Tag = 'a',
        toUrl = null;

    const {isShowing, toggle} = useModal();

    /*
        All contentful_id's are reduced to their first 3 characters.
        The contentful_id's are then concatenated like so:
        {section contentful_id}-{parent contentful_id}-{link contentful_id [if applicable]}
        lid = Link ID
        uid = Unique ID

        This provides a static section > component > link specific ID
        which is usful for targeting elements in unit / functional testing
        as well as analytics.
    */
    const lid = link ? `${link.contentful_id}` : `${contentful_id}`;
    const uid = `${sid && sid.substring(0,3)}-${pid && pid.substring(0,3)}-${lid.substring(0,3)}`;

    const isRelative = /^\/(?!\/)/.test(url); // starts with exactly 1 slash ('/')
    const isFile = /\.[0-9a-z]+$/i.test(url);

    if (isRelative && !isFile) {
        Tag = GatsbyLink;
        toUrl = gatsbyLinkUrl; //only render 'to' attr if using GatsbyLink
    };

    useEffect(() => {
        setGatsbyLinkUrl(getRelativeUrl(url))
        setHref(getAbsoluteUrl(url));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const createRedirect = (target) => {
        if (target.target === '_blank') {
            const newLink = document.createElement('a');
                newLink.href = target.href;
                newLink.target = '_blank';
                newLink.click();
        } else {
            window.location.href = target.href;
        }
    }

    const onClickLink = (e) => {
        let target = e.target;

        if ((url !== undefined) && (url.includes('#'))) {
            moveToAnchorId(url)
        }

        if (modal) {
            e.preventDefault();
            toggle();
        }

        if (typeof onClick === 'function') {
            onClick();
        }

        if (typeof window !== undefined) {
            // In case the link contains an image or pseudo element
            if (target.href === undefined) {
                target = e.target.closest('a');
            }

            if (!modal) {
                const origin = window.location.origin,
                    propData = new URL(href, origin),
                    targetData = new URL(target.href);

                // Same origins
                if (propData.origin === targetData.origin) {
                    // Different pathnames / search / hash
                    if (propData.pathname !== targetData.pathname ||
                        propData.search !== targetData.search ||
                        propData.hash !== targetData.hash) {
                            e.preventDefault();
                            createRedirect(target);
                        }
                // Different origins
                } else {
                    e.preventDefault();
                    createRedirect(target);
                }
            }
        }
    }

    return (
        <React.Fragment>
            <Tag
              to={toUrl} // not using getAbsoluteUrl(...) helper b/c that's only needed to cover go.constantcontact.com URLs, which are not relative
              tabIndex={`${tabindex ? tabindex : 0}`}
              aria-label={ariaLabel}
              className={className}
              href={href}
              data-qe-id={`${dataQeId}-link-${uid}`}
              data-om-config={`${dataQeId}-link, ${eventActionText || text} ${uid}${getOmUrl(url)}`}
              rel={rel}
              onClick={onClickLink}
              itemProp={itemProp}
              title={title}
              target={target &&
                  (target.toLowerCase() === 'new window' || target === '_blank') ? '_blank' : null }
            >
              {children}
              {text}
            </Tag>
            {modal &&
                <Modal
                    currencySymbol={currencySymbol}
                    isShowing={isShowing}
                    planType={planType}
                    pricing={pricing}
                    toggle={toggle}
                    {...modal}
                />
            }
        </React.Fragment>
      )
}

Link.propTypes = {
    dataQeId: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string
}

Link.defaultProps = {
    contentful_id: 'rogue',
    dataQeId: 'rogue',
    parent: {
        contentful_id: 'rogue'
    },
    section: {
        contentful_id: 'rogue'
    },
}

export default Link;
