import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextBlock from '../text-block';
import CTAButton from '../cta-button';
import { KebabCase, parseHeadline } from '../../assets/scripts/helpers';
import './one-column-text-block.scss';

const OneColumnTextBlock = props => {
    const {
            contentful_id,
            id,
            textAlignment,
            introText,
            headline,
            image,
            content,
            ctaButton,
            ...attributes
        } = props,
        oneColumnBody = useRef(null),
        [percentage, setPercentage] = useState(0),
        scaleValueStart = 1.8,
        scaleValueEnd = 1,
        [scale, setScale] = useState(scaleValueStart),
        parent = {
            contentful_id,
            id
        };

    useEffect(() => {

        const onScroll = () => {
            // The random 85 is to offset the height of the sticky header
            let oneColumnStop = oneColumnBody.current && oneColumnBody.current.offsetTop - 85;

            const scrollY = window.pageYOffset || document.body.scrollTop,
                oneColumnTopOffset = 600,
                oneColumnStart = scrollY - (oneColumnStop - oneColumnTopOffset),
                oneColumnPercentage =
                    (oneColumnStart / oneColumnTopOffset) * 100;


            if (oneColumnStart >= 0 && scrollY <= oneColumnStop) {
                setScale(
                    scaleValueStart -
                        (scaleValueStart - scaleValueEnd) *
                            (oneColumnPercentage / 100)
                );
                setPercentage(oneColumnPercentage);
            }

            if (oneColumnStart < 0) {
                setPercentage(0);
                setScale(scaleValueStart);
            }

            if (scrollY > oneColumnStop) {
                setPercentage(100);
                setScale(scaleValueEnd);
            }
        };

        if (image !== null && typeof window !== 'undefined') {
            window.addEventListener('scroll', onScroll);
            window.addEventListener('resize', onScroll);
            return () => {
                window.removeEventListener('scroll', onScroll);
                window.removeEventListener('resize', onScroll);
            };
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getClasses = () => {

        let classes = [
            `one-column-text-block`,
            `one-column-text-block--${KebabCase(attributes.section.background)}`,
            `${textAlignment}`
        ];

        return classes.join(' ');
    }

    return (
        <div className={getClasses()} >
            {(headline || introText) && (
                <div className="one-column-text-block__header">
                    {introText && (
                        <p className="one-column-text-block__intro">{introText}</p>
                    )}
                    {headline && (
                        <h2 dangerouslySetInnerHTML={{__html: parseHeadline(headline)}} />
                    )}
                </div>
            )}
            {image && (
                <div className="one-column-text-block__body" ref={oneColumnBody}>
                    <div className="content">
                        <div
                            className={`one-column-text-block__border one-column-text-block__border--left`}
                            style={{
                                transform: `translate3d(${-percentage}%, 0, 0)`,
                            }}
                        ></div>
                        <img
                            className="one-column-text-block__image"
                            src={image.file.url}
                            alt=""
                            style={{
                                transform: `scale3d(${scale}, ${scale}, 1)`,
                            }}
                            loading="lazy"
                        />
                        <div
                            className={`one-column-text-block__border one-column-text-block__border--right`}
                            style={{
                                transform: `translate3d(${percentage}%, 0, 0)`,
                            }}
                        ></div>
                    </div>
                </div>
            )}
            {(content || ctaButton) && (
                <div className="one-column-text-block__footer">
                    {content && <TextBlock {...attributes} {...content} contentful_id={contentful_id} dataQeId="one-column-text-block" />}
                    {ctaButton && (
                        <CTAButton
                            {...attributes}
                            {...ctaButton}
                            parent={parent}
                            dataQeId="one-column-text-block"
                        />
                    )}
                </div>
            )}
        </div>
    );
};

OneColumnTextBlock.propTypes = {
    headline: PropTypes.string,
    image: PropTypes.object,
    content: PropTypes.object,
    wistiaHash: PropTypes.string,
};

export default OneColumnTextBlock;
