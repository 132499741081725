import React from 'react';
import PropTypes from 'prop-types';
import './header.scss';
import Lank from '../link';
import CtaButton from '../cta-button/';
import { getQueryParamVal } from '../../assets/scripts/helpers';

class HeaderView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            isMobileView: false,
            active: [],
            loginLink: '',
            dontHaveState: this.props.dontHaveAnAccount
        };
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.handleWindowScroll = this.handleWindowScroll.bind(this);
        this.headerRef = React.createRef();
    }

    clickNavToggle = event => {
        event.preventDefault();

        this.setState({
            menuOpen: !this.state.menuOpen,
        });
    };

    handleWindowResize = () => {
        this.setState({
            menuOpen: false,
            isMobileView: window.innerWidth < 1400,
        });

        /*
        * keep nav elements from flashing when window resizes
        * by adding a class to stop animations on window resize
        */
        document.documentElement.classList.add('resize-animation-stopper');
        setTimeout(() => {
            document.documentElement.classList.remove('resize-animation-stopper');
        }, 500);
    };

    handleWindowScroll = () => {
        let header = this.headerRef.current;

        if (this.props.children) {
            if (this.props.children.props.hasOwnProperty('showBannerOnlyOnce')) {
                if (this.props.sticky) {
                    if (window.scrollY <= this.props.height) {
                        header.classList.remove('navbar--sticky');
                    }

                    if (header.getBoundingClientRect().top <= 0) {
                        header.classList.add('navbar--sticky');
                    }
                }
            }
        }
    }

    /**
     * Add event listener to window
     */
    componentDidMount() {
        this.handleWindowResize();

        this.handleWindowScroll();

        window.addEventListener(
            'resize',
            this.handleWindowResize.bind(this)
        )

        window.addEventListener(
            'scroll',
            this.handleWindowScroll.bind(this)
        )

        if (typeof window !== 'undefined') {
            if (getQueryParamVal(window.location.search, 'provisioning') === 'true') {
                this.setState({ dontHaveState: false });
            }
        }

        //if there's a banner & there's no close button, remove the sticky class on header
        if (this.props.children) {
            if (this.props.children.props.hasOwnProperty('showBannerOnlyOnce')) {
                if (!this.props.children.props.displayCloseButton) {
                    this.headerRef.current.classList.remove('navbar--sticky')
                }
            }
        }
    };

    /**
     * Remove event listener from window
     */
    componentWillUnmount() {
        window.removeEventListener(
            'resize',
            this.handleWindowResize.bind(this)
        );

        window.removeEventListener(
            'scroll',
            this.handleWindowScroll.bind(this)
        )

        clearTimeout(setTimeout(() => {
            document.documentElement.classList.remove('resize-animation-stopper');
        }, 500));
    };

    onEnter = (i) => {
        if (!this.state.active.includes(i)) {
            this.setState({ active: [i] });
        } else {
            this.onExit();
        }
    };

    onExit = () => {
        this.setState({ active: [] });
    };

    displayLogo() {
        let src;

        const {
            logo
        } = this.props;

        const whiteOrange = "https://images.ctfassets.net/t21gix3kzulv/78gf1S3CjPrnl9rURf6Q8w/3c20fb510dd4d4653feddf86ece35e1a/ctct_ripple_logo_horizontal_white_orange.svg",
            blueOrange = "https://images.ctfassets.net/nw29p03vv8ee/3PpcmF2GOeJ00YbheI07cO/07e26a2f5864bbd664710c2c3c5a87f3/ReSci-From-CTCT-Logo-RGB-Color_nav_bar.svg",
            white = "https://images.ctfassets.net/nw29p03vv8ee/2dHVPiqmVLqM0GjQ2KVjJ5/804039b55fbdab269cdc873fb56c9967/RESCI_Logo_white.svg",
            blue = "https://images.ctfassets.net/t21gix3kzulv/6OQp2nP2XFLaiwUihRU3Ni/a1cb2c2a322df48404f818ecf0c3ec61/ctct_ripple_logo_horizontal_blue.svg";

        if (logo.includes("white")) {
            logo.includes("orange") ? src = whiteOrange : src = white
        } else {
            logo.includes("orange") ? src = blueOrange : src = blue
        }

        return <img src={src} alt="Retention Science Logo" className="resci-logo" height="43" width="249" />
    };

    displaySignUpBtn = (option) => {

        option = option || {};
        const uid = option.sub ? 'ssb' : 'nsb';

        return (
            <li className="navbar__utility-item sign-up-button">
                <CtaButton
                    contentful_id={uid}
                    buttonText="Log in"
                    color="blue"
                    dataQeId="header-signup-button"
                    parent={{contentful_id: "hsb"}}
                    section={{contentful_id: this.props.contentful_id}}
                    target="Same Window"
                    size="regular"
                    url={`${this.props.internationalLinks ? 'https://members.retentionscience.com/sign_in' : 'https://members.retentionscience.com/sign_in'}`}
                />
            </li>
        )
    }

    displayBuyNowBtn = (option) => {
        option = option || {};
        const uid = option.sub ? 'sbn' : 'nbn';

        return (
            <li className="navbar__utility-item buy-now-button">
                <CtaButton
                    contentful_id={uid}
                    buttonText="Buy Now"
                    color="orange"
                    dataQeId="header-buy-now-button"
                    parent={{contentful_id: 'hbn'}}
                    section={{contentful_id: this.props.contentful_id}}
                    target="Same Window"
                    size="regular"
                    url="/buynow/plans/signup.jsp"
                />
            </li>
        )
    }

    displayAlreadyHaveAccount = (option) => {

        option = option || {};
        const uid = option.sub ? 'saa' : 'naa';

        return (
            <li className="navbar__utility-item already-account-button">
                Already have an account?
                <Lank
                    ariaLabel="Already have an account"
                    url="/login"
                    dataQeId="header-login"
                    parent={{contentful_id: uid}}
                    section={{contentful_id: this.props.contentful_id}}
                    link={{contentful_id: 'aab'}}
                    target="_blank"
                    text="Log in"
                    rel="noopener noreferrer"
                />
            </li>
        )
    }

    displayDontHaveAnAccount = (option) => {

        option = option || {};
        const uid = option.sub ? 'daa' : 'dha';

        return (
            <li className="navbar__utility-item dont-account-button">
                Don't have an account?
                <Lank
                    ariaLabel="Sign up"
                    url="https://go.constantcontact.com/buynow/product/signup.jsp"
                    dataQeId="header-signup"
                    parent={{contentful_id: uid}}
                    section={{contentful_id: this.props.contentful_id}}
                    link={{contentful_id: 'dab'}}
                    target="_blank"
                    text="Sign up"
                    rel="noopener noreferrer"
                />
            </li>
        )
    }

    displayGoToMyAccount = (option) => {

        option = option || {};
        const uid = option.sub ? 'sga' : 'nga';

        return (
            <li className="navbar__utility-item my-account-button">
                <CtaButton
                    contentful_id={uid}
                    buttonText="Got to my account"
                    target="Same Window"
                    parent={{contentful_id: 'gta'}}
                    section={{contentful_id: this.props.contentful_id}}
                    size="regular"
                    color="outline"
                    url="#"
                    dataQeId="header-go-to-my-account-button"/>
            </li>
        )
    }

    displayLogInBtn = (option) => {
        option = option || {};
        const uid = option.sub ? 'sli' : 'nli';

        {/*return (
             <li className="navbar__utility-item log-in-button">
                <CtaButton
                    contentful_id={uid}
                    buttonText="Log in"
                    target="Same Window"
                    parent={{contentful_id: 'hli'}}
                    section={{contentful_id: this.props.contentful_id}}
                    size="regular"
                    color="outline"
                    url="https://members.retentionscience.com/sign_in"
                    dataQeId="header-login-button"/>
            </li> 
        )*/}
    }

    displayPhone = (section, option) => {
        option = option || {};
        const uid = option.sub ? 'spn' : 'npn';

        return (
            <li className="navbar__utility-item phone-number">
                <Lank
                    uid={uid}
                    tabIndex="0"
                    className="navbar__phone-number"
                    dataQeId="header-phone"
                    parent={{contentful_id: uid}}
                    section={section}
                    link={{contentful_id: 'phone'}}
                    url="tel: 855-783-2308"
                    text="855-783-2308"
                />
            </li>
        )
    };


    render() {
        const { menuOpen, isMobileView, active, dontHaveState } = this.state,
        {
            alreadyHaveAccount,
            backgroundColor,
            buyNowButton,
            contentful_id,
            children,
            goToMyAccount,
            id,
            links,
            loginButton,
            phone,
            signupButton,
            sticky,
            internationalLinks,
            height
        } = this.props,
        section = {
            contentful_id,
            id
        };

        let provisioning = false;

        if (typeof window !== 'undefined') {
            provisioning = getQueryParamVal(window.location.search, 'provisioning') === 'true';
        }

        return (
            <>
                {children}
                <header
                    className={`navbar${sticky ? ' navbar--sticky' : ''}${menuOpen ? ' show-menu' : ''}${backgroundColor === "Transparent" ? ' transparent' : backgroundColor === "White" ? ' white' : ''}${alreadyHaveAccount || dontHaveState || goToMyAccount ? ' hide-hamburger' : ''}${!links ? ` no-links` : ''}`}
                    id="navbar"
                    ref={this.headerRef}
                    style={this.props.children ? (this.props.children.props.displayCloseButton ? {marginTop: `${height}px`} : null) : null}
                >
                    <div className="container container--navbar">
                        <Lank
                            className="navbar__logo resci-logo--solo"
                            dataQeId="header-logo"
                            eventActionText="logo"
                            parent={{contentful_id: 'nav'}}
                            section={section}
                            link={{contentful_id: 'logo'}}
                            url={`${internationalLinks ? '/home-page' : '/'}`}
                        >
                            {this.displayLogo(internationalLinks)}
                        </Lank>
                        <span className="screen-reader-text" data-nosnippet>If you are using assistive technology and are unable to read any part of the Retention Science website, or otherwise have difficulties using the Retention Science website, please call <a href="tel:877-358-5969">877-358-5969</a> and our customer service team will assist you.</span>
                        {[phone, loginButton, signupButton, goToMyAccount, alreadyHaveAccount, dontHaveState].indexOf(true) !== -1 &&
                            <ul className={`navbar__utility-nav${menuOpen ? ' menu-open' : ''}`}>
                                {phone && this.displayPhone(section)}
                                {loginButton && this.displayLogInBtn()}
                                {(signupButton || buyNowButton) && (
                                    signupButton ? this.displaySignUpBtn() : this.displayBuyNowBtn()
                                )}
                                {goToMyAccount && this.displayGoToMyAccount
                                    (phone && this.displayPhone(section))
                                }
                                {alreadyHaveAccount && this.displayAlreadyHaveAccount()}
                                {dontHaveState && !provisioning && this.displayDontHaveAnAccount()}
                                {(links || phone || loginButton || signupButton || goToMyAccount || alreadyHaveAccount || dontHaveState) &&
                                    <li className="navbar__toggle">
                                        <CtaButton
                                            contentful_id="htg"
                                            dataQeId="header-toggle"
                                            aria-label="Main Navigation Toggle"
                                            eventActionText="menu-toggle"
                                            onClick={this.clickNavToggle}
                                            parent={{contentful_id: 'tog'}}
                                            section={section}
                                            type="button"
                                        >
                                            <div className="navbar__icon">
                                                <span className="bar" />
                                                <span className="bar" />
                                                <span className="bar" />
                                                <span className="bar" />
                                            </div>
                                        </CtaButton>
                                    </li>
                                }
                            </ul>
                        }
                        <nav
                            role="navigation"
                            aria-label="Main Navigation"
                            className={`navbar__menu${(menuOpen && isMobileView) ? ' open' : (!menuOpen && isMobileView) ? ' close' : ''}`}
                            itemScope
                            itemType="http://schema.org/SiteNavigationElement"
                        >
                            {links &&
                                <ul
                                    className="navbar__global-nav"
                                >
                                        {links.map((link, i) => {
                                            const { links,
                                                headline,
                                                contentful_id: linkId
                                            } = link,
                                            linkParent = { contentful_id: linkId };

                                            return (
                                                <React.Fragment key={i}>
                                                    {links.length === 1 && (
                                                        <React.Fragment>
                                                            {links.map((link, i) => {
                                                                return (
                                                                    <li
                                                                        className="navbar__item"
                                                                        key={i}
                                                                        itemProp="name"
                                                                    >
                                                                        <Lank
                                                                            className="navbar__link navbar__link--solo"
                                                                            dataQeId="header-single-link"
                                                                            link={{contentful_id: link.contentful_id}}
                                                                            parent={linkParent}
                                                                            target={link.target}
                                                                            text={link.linkText}
                                                                            url={link.url}
                                                                            section={section}
                                                                            itemProp="url"
                                                                            title={link.linkText}
                                                                        />
                                                                    </li>
                                                                )
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                                    {links.length > 1 && (
                                                        <li className={`navbar__item${active.indexOf(i) > -1 ? ' open' : ''}`}  // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
                                                            onClick={() => this.onEnter(i)}
                                                            onKeyDown={() => this.onEnter(i)}
                                                            onMouseEnter={!isMobileView ? () => this.onEnter(i) : null}
                                                            onMouseLeave={!isMobileView ? () => this.onExit(i) : null}
                                                        >
                                                            <CtaButton
                                                                section={section}
                                                                className={`navbar__link${active.indexOf(i) > -1 ? ' open' : ''}`}
                                                                dataQeId="header-nav-item"
                                                                eventActionText={headline}
                                                                parent={linkParent}
                                                                type="button"
                                                                contentful_id={`${i}`}
                                                                itemProp="url"
                                                                title={headline}
                                                            >
                                                                {headline}
                                                                {link && (
                                                                    <div className={`arrow${isMobileView ? ' mobile' : ''}`}></div>
                                                                )}
                                                            </CtaButton>
                                                            {links && (
                                                                <div className={`navbar__submenu-wrapper${active.indexOf(i) > -1 ? ' open' : ''}`}>
                                                                    <ul
                                                                        className={`navbar__submenu ${active.indexOf(i) > -1 ? ' open' : ''}`}
                                                                        >
                                                                        <li
                                                                            className="navbar__item navbar__back"
                                                                        >
                                                                            <CtaButton
                                                                                contentful_id={`hb${i}`}
                                                                                tabindex={active.indexOf(i) > -1 ? 0 : -1}
                                                                                section={section}
                                                                                className="js-submenu-back"
                                                                                dataQeId="header-submenu-back"
                                                                                eventActionText="Back"
                                                                                parent={{contentful_id: 'bck'}}
                                                                                type="button"
                                                                            >
                                                                                <svg width="9px" height="17px" viewBox="0 0 9 17" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M7.484 1L9 2.515 3.013 8.5 9 14.485 7.484 16 0 8.52l.02-.02L0 8.48z" fill="#1856ED" stroke="#1856ED" strokeWidth="0.900000036" fillRule="evenodd"/>
                                                                                </svg>
                                                                                Back
                                                                            </CtaButton>
                                                                            <h6>{headline}</h6>
                                                                        </li>
                                                                        {links.map((nav_level2, index) => {
                                                                            const {
                                                                                contentful_id: blem,
                                                                                target,
                                                                                linkText,
                                                                                url
                                                                            } = nav_level2;

                                                                            return (
                                                                                <li
                                                                                    className='navbar__item navbar__item--level-2'
                                                                                    key={index}
                                                                                    itemProp="name"
                                                                                >
                                                                                <Lank
                                                                                    section={section}
                                                                                    tabindex={active.indexOf(i) > -1 ? 0 : -1}
                                                                                    className="navbar__link"
                                                                                    dataQeId="header-links"
                                                                                    link={{contentful_id: blem}}
                                                                                    parent={linkParent}
                                                                                    target={target}
                                                                                    text={linkText}
                                                                                    url={url}
                                                                                    itemProp="url"
                                                                                    title={linkText}
                                                                                />
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </li>
                                                    )}
                                                </React.Fragment>
                                            )
                                        })}
                                </ul>
                            }
                            <ul className="navbar__utility-nav--mobile">
                                { phone && this.displayPhone(section, {sub: true})}
                                { loginButton && this.displayLogInBtn({sub: true})}
                                {(signupButton || buyNowButton) && (
                                    signupButton ? this.displaySignUpBtn({sub: true}) : this.displayBuyNowBtn({sub: true})
                                )}
                                { alreadyHaveAccount && this.displayAlreadyHaveAccount({sub: true})}
                                { dontHaveState && !provisioning && this.displayDontHaveAnAccount({sub: true})}
                                { goToMyAccount && this.displayGoToMyAccount({sub: true})}
                            </ul>
                        </nav>
                    </div>
                </header>
            </>
        );
    }
}

HeaderView.propTypes = {
    alreadyHaveAccount: PropTypes.bool.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    goToMyAccount: PropTypes.bool.isRequired,
    loginButton: PropTypes.bool.isRequired,
    logo: PropTypes.string.isRequired,
    phone: PropTypes.bool.isRequired,
    signupButton: PropTypes.bool.isRequired,
    sticky: PropTypes.bool.isRequired
};

HeaderView.defaultProps = {
    logo: "https://images.ctfassets.net/t21gix3kzulv/78gf1S3CjPrnl9rURf6Q8w/3c20fb510dd4d4653feddf86ece35e1a/ctct_ripple_logo_horizontal_white_orange.svg"
};

export default HeaderView;
