import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import axios from 'axios';
import Image from '../image';
import CtaButton from '../cta-button';
import { loadScript } from '../../assets/scripts/helpers';
import './video-block.scss';

const Video = (props) => {

    const {
            buttonSubText,
            buttonText,
            contentful_id,
            dataQeId,
            id,
            videoImage: image,
            imageAlt,
            wistiaShareLink,
            ...attributes
        } = props,
        [ isLoaded, setIsLoaded ] = useState(false),
        [ wistia, setWistia ] = useState({
            hash: wistiaShareLink.split('/').pop(),
            poster: {
                url: ''
            }
        }),
        {
            hash,
            poster: {
                url
            }
        } = wistia,
        parent = {
            contentful_id,
            id
        };

    useEffect(() => {
        // Call Wistia to get the video's data
        if (image === null || image === undefined) {
            axios
                .get(`https://fast.wistia.com/embed/medias/${hash}.json`)
                .then(res => {
                    // I only need the still image uploaded to Wistia but could get anything
                    setWistia({ ...wistia, poster: res.data.media.assets.filter(item => /still_image/.test(item.type))[0] })
                });
        }

        loadScript('https://fast.wistia.com/assets/external/E-v1.js', () => {
            setIsLoaded(true);
        });

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className="video"
        >
            <Helmet>
                <script src={`https://fast.wistia.com/embed/medias/${hash}.jsonp`} async></script>
            </Helmet>
            <div className="video__poster">
                {image ?
                    (
                        <Image {...image} lazyload={false} />
                    ) : (
                        <img
                            src={url}
                            alt={imageAlt}
                            loading="lazy"
                        />
                    )
                }
            </div>
            <div
                className={`wistia_embed wistia_async_${hash} popover=true popoverContent=link preload=none video__play-button id=${hash} `}
            >
                <CtaButton
                    {...attributes}
                    type="button"
                    eventActionText={buttonText}
                    className={`video__button ${isLoaded ? 'video__button--loaded' : ''}`}
                    dataQeId={`${dataQeId && dataQeId + '-'}video-play-button`}
                    parent={parent}
                >
                    <div className="video__play-button-circle">
                        <svg width="26" height="30.81" viewBox="0 0 26 30.81">
                            <path d="M1.835.264A3.083 3.083 0 000 3.084V27.73a3.084 3.084 0 005.157 2.283L24.99 17.69a3.083 3.083 0 000-4.565L5.157.8A3.084 3.084 0 001.835.264z" fill="#fff"/>
                        </svg>
                    </div>
                    {(buttonText || buttonSubText) && (
                        <div className="video__play-button-text">
                            <div className="video__play-button-headline">
                                {buttonText}
                            </div>
                            <div className="video__play-button-sub-headline">
                                {buttonSubText}
                            </div>
                        </div>
                    )}
                </CtaButton>
            </div>
        </div>
    );
}

Video.propTypes = {
    buttonText: PropTypes.string,
    buttonSubText: PropTypes.string,
    wistiaShareLink: PropTypes.string.isRequired,
    imageAlt: PropTypes.string
}

export default Video;
