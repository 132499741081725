import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { KebabCase, ValidateField, getCookie, getQueryParamVal} from '../../assets/scripts/helpers';
import CtaButton from '../cta-button';
import Loading from '../../assets/svg/loading';
import './input.scss';

const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

const Input = props => {

    const {
            callback,
            className,
            contentful_id,
            id,
            dataQeId,
            json,
            min,
            inputColor,
            inputName:name,
            inputLabel:label,
            inputType:type,
            inputRequired:required,
            inputValue,
            placeholder,
            placeholderText,
            submitting,
            ...attributes
        } = props;

    const [input, setInput] = useState({
            value: '',
            focus: false,
            error: {
                status: false,
                message: ''
            },
            password: {
                visibility: false
            },
            type: type
        }),
        {
            value,
            focus,
            error: {
                status,
                message
            },
            password: {
                visibility:passwordVisibility
            },
            type:inputType
        } = input;

        useEffect(() => {
            let search;
            /**
             * Values for this field can come from the following places:
             *   Hardcoded value from Contentful
             *   Query params
             *   Cookies
             */

            if (inputValue) {
                setInput({...input, value: inputValue});
            }

            if (typeof window !== 'undefined') {
                search = window.location.search;

                if (getCookie(name) !== '') {
                    setInput({...input, value: getCookie(name)});
                }

                if (getQueryParamVal(search, name) !== '') {
                    setInput({...input, value: getQueryParamVal(search, name)});
                }
            }
        }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fieldId = name && KebabCase(name),
        fieldsetClass = (status ? 'error' : '') + (focus ? ' focus' : ''),
        inputClass = value || placeholderText ? 'push' : '',
        messageClass = status ? 'message--error' : '';

    return (
        <React.Fragment>
            {type === 'submit' ?
                (
                    <CtaButton
                        {...attributes}
                        color={inputColor ? inputColor : 'blue'}
                        dataQeId={`${dataQeId}-submit`}
                        eventActionText={label}
                        size="large"
                        type="submit"
                        disabled={attributes.disabled}
                        contentful_id={contentful_id}
                    >
                        {submitting &&
                            <Loading />
                        }
                        {label}
                    </CtaButton>
                ) : (
                    <ConditionalWrapper
                        condition={type !== 'hidden'}
                        wrapper={children => (
                            <div className={`field ${className}`}>
                                {children}
                            </div>
                        )}
                    >
                        <ConditionalWrapper
                            condition={type !== 'hidden'}
                            wrapper={children => (
                                <fieldset className={fieldsetClass}>
                                    {children}
                                </fieldset>
                            )}
                        >
                            <input
                                id={fieldId}
                                className={inputClass}
                                data-qe-id={`${dataQeId}-${fieldId}`}
                                data-type={type}
                                value={inputValue || value}
                                name={name}
                                required={required}
                                type={inputType}
                                disabled={attributes.disabled}
                                onBlur={e => ValidateField(e, (v) => {
                                    setInput({
                                        ...input,
                                        focus: false,
                                        error: {
                                            status: v.status,
                                            message: v.message
                                        }
                                    })
                                })}
                                onChange={(e) => {
                                    setInput({...input, value: e.target.value})
                                    if (props.onChangeCallback) {
                                        props.onChangeCallback(e);
                                    }
                                }}
                                placeholder={placeholderText || placeholder}
                                min={min}
                            />
                            {type !== 'hidden' &&
                                <label htmlFor={fieldId}>{label}</label>
                            }
                            {type === 'password' &&
                                <CtaButton
                                    buttonText="Toggle password visibility"
                                    className={`password-toggle ${passwordVisibility ? 'toggled' : ''}`}
                                    contentful_id="ord"
                                    dataQeId="password-toggle"
                                    section={{
                                        "contentful_id": "pas",
                                        "id": "pas"
                                    }}
                                    onClick={e => setInput({
                                            ...input,
                                            type: inputType === 'password' ? 'text' : 'password',
                                            password: { visibility: !passwordVisibility}
                                        })
                                    }
                                    parent={{
                                        "contentful_id": "s_w",
                                        "id": "s w"
                                    }}
                                    type="button"
                                />
                            }
                        </ConditionalWrapper>
                        {type !== 'hidden' &&
                            <div className={`message ${messageClass}`}>
                                {required &&
                                    <React.Fragment>
                                        <sup>*</sup>Required
                                    </React.Fragment>
                                }
                                {message &&
                                    <React.Fragment>: {message}</React.Fragment>
                                }
                            </div>
                        }
                    </ConditionalWrapper>
                )
            }
        </React.Fragment>
    );
};

Input.propTypes = {
    dataQeId: PropTypes.string.isRequired,
    inputLabel: PropTypes.string,
    inputName: PropTypes.string.isRequired,
    inputType: PropTypes.string.isRequired,
    inputRequired: PropTypes.array,
};

export default Input;
