export const industryData = {
    "Apparel, Accessories, and Shoes": {
        "$0 - $500K": 13,
        "$501K - $1MM": 31,
        "$1MM - $5MM": 71,
        "$5MM - $20MM": 103,
        "$20MM - $50MM": 138
    },
    "Automotive": {
        "$0 - $500K": 14,
        "$501K - $1MM": 33,
        "$1MM - $5MM": 76,
        "$5MM - $20MM": 110,
        "$20MM - $50MM": 147
    },
    "Electronics, Computers, and Video Equipment": {
        "$0 - $500K": 13,
        "$501K - $1MM": 29,
        "$1MM - $5MM": 67,
        "$5MM - $20MM": 97,
        "$20MM - $50MM": 130
    },
    "Food, Drug, Beverages, and Tobacco": {
        "$0 - $500K": 13,
        "$501K - $1MM": 29,
        "$1MM - $5MM": 67,
        "$5MM - $20MM": 97,
        "$20MM - $50MM": 130
    },
    "Grocery Retail": {
        "$0 - $500K": 13,
        "$501K - $1MM": 30,
        "$1MM - $5MM": 68,
        "$5MM - $20MM": 99,
        "$20MM - $50MM": 133
    },
    "Hardware, Building Materials, and DIY": {
        "$0 - $500K": 12,
        "$501K - $1MM": 29,
        "$1MM - $5MM": 67,
        "$5MM - $20MM": 97,
        "$20MM - $50MM": 129
    },
    "Health, Beauty and Cosmetics": {
        "$0 - $500K": 13,
        "$501K - $1MM": 30,
        "$1MM - $5MM": 68,
        "$5MM - $20MM": 98,
        "$20MM - $50MM": 132
    },
    "Housewares, Garden, and Home Furnishings": {
        "$0 - $500K": 13,
        "$501K - $1MM": 31,
        "$1MM - $5MM": 71,
        "$5MM - $20MM": 103,
        "$20MM - $50MM": 138
    },
    "Jewelry, Gems, and Precious Metals": {
        "$0 - $500K": 14,
        "$501K - $1MM": 32,
        "$1MM - $5MM": 73,
        "$5MM - $20MM": 106,
        "$20MM - $50MM": 143
    },
    "Mass Merchant and Variety": {
        "$0 - $500K": 13,
        "$501K - $1MM": 31,
        "$1MM - $5MM": 71,
        "$5MM - $20MM": 103,
        "$20MM - $50MM": 138
    },
    "Sporting Goods and Outdoor": {
        "$0 - $500K": 14,
        "$501K - $1MM": 32,
        "$1MM - $5MM": 74,
        "$5MM - $20MM": 107,
        "$20MM - $50MM": 143
    },
    "Toys, Crafts, Science, and Hobbies": {
        "$0 - $500K": 13,
        "$501K - $1MM": 31,
        "$1MM - $5MM": 71,
        "$5MM - $20MM": 103,
        "$20MM - $50MM": 138
    },
};
