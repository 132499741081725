import React from 'react';
import PropTypes from 'prop-types';
import { KebabCase } from '../../assets/scripts/helpers';
import TextBlock from '../text-block';
import CtaButton from '../cta-button';
import './card.scss';

const CardWrapper = props => {
    const {
        cards,
        headline,
        introText,
        subHeadline,
        ...attributes
    } = props;

    return (
        <div className="card-wrapper">
            {(introText || headline || subHeadline) && (
                <div className="card-wrapper__header">
                    {introText && (
                        <p className="card-wrapper__intro-text">{introText}</p>
                    )}
                    {headline && <h2>{headline}</h2>}
                    {subHeadline && (
                        <p className="card-wrapper__subhead">{subHeadline}</p>
                    )}
                </div>
            )}
            <div className={`card-wrapper__body${cards.length > 2 ? ' card-wrapper__body--left' : ' card-wrapper__body--centered'}`}>
                {cards.map((card, i) => {
                    const {
                        backgroundColor,
                        centerAlign,
                        content,
                        contentful_id,
                        introText,
                        cta,
                        heading,
                        headingSize,
                        id,
                        image,
                        imageAlt,
                    } = card;

                    const parent = {
                        contentful_id: contentful_id,
                        id: id,
                    };

                    return (
                        <div
                            className={`card card--${KebabCase(backgroundColor)}${cta ? ' hover-effect' : ''}${centerAlign ? ' center-align' : ''}`}
                            key={i}
                        >
                            {image && (
                                <div className="card__header">
                                    <img
                                        className="card__image"
                                        alt={imageAlt}
                                        src={image.file.url}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                            <div className="card__body">
                                {introText && (
                                    <p className="card__intro-text">
                                        {introText}
                                    </p>
                                )}
                                <h3
                                    className={`card__heading card__heading--${headingSize}`}
                                >
                                    {heading}
                                </h3>
                                <TextBlock
                                    {...content}
                                    {...attributes}
                                    dataQeId="card"
                                />
                            </div>
                            {cta &&
                                <div className="card__footer">
                                    <CtaButton
                                        {...cta}
                                        {...attributes}
                                        dataQeId="card"
                                        parent={parent}
                                    />
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

CardWrapper.propTypes = {
    introText: PropTypes.string,
    headline: PropTypes.string,
    subHeadline: PropTypes.string,
    cards: PropTypes.arrayOf(
        PropTypes.shape({
            backgroundColor: PropTypes.string.isRequired,
            content: PropTypes.object.isRequired,
            cta: PropTypes.object,
            introText: PropTypes.string,
            heading: PropTypes.string.isRequired,
            headingSize: PropTypes.string.isRequired,
            image: PropTypes.object,
            imageAlt: PropTypes.string,
        })
    ),
};

export default CardWrapper;
