import React from 'react';
import { VelocityComponent } from 'velocity-react';

class VelocityBandaidView extends React.Component {
    constructor(props) {
        super(props);
        this.begin = this.begin.bind(this);
    }

    begin(elements) {
        if (this.props.animation === 'slideDown') {
            elements.forEach(el => {
                if (el.style.display === 'none') {
                    el.style.height = null;
                }
            });
        }
    }

    render() {
        const props = Object.assign({}, {begin: this.begin}, this.props);

        return (
            <VelocityComponent {...props}>
                {this.props.children}
            </VelocityComponent>
        );
    }
}

export default VelocityBandaidView;
