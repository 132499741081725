import React from 'react';
import PropTypes from 'prop-types';
import './media-block.scss';
import CTAButton from '../cta-button/';
import CTAButtonWrapper from '../cta-button-wrapper/';
import TextBlock from '../text-block';

const MediaBlockWrapper = props => {
    const {
            introText,
            headline,
            paragraph,
            ctaButtonOrCtaButtonWrapper,
            attribution,
            mediaBlocks,
            ...attributes
        } = props,
        classes = props.className || '';

    return (
        <div className="media-block__wrapper">
            {(introText || headline || paragraph) && (
                <div className="media-block__header">
                    {introText && (
                        <p className="media-block__intro-text">{introText}</p>
                    )}
                    {headline && <h2>{headline}</h2>}
                    {paragraph && (
                        <div className="media-block__paragraph">
                           <TextBlock {...paragraph} />
                        </div>
                    )}
                </div>
            )}
            <div className={`media-block__body media-block__body--count-${mediaBlocks.length}${mediaBlocks.length > 4 ? ' start-right' : ''}`}>
                {mediaBlocks.map((mediaBlock, i) => {
                    const { cta, headline, content, icon, contentful_id, id } = mediaBlock,
                    parent = {
                        contentful_id,
                        id
                    };

                    return (
                        <div className={`media-block${ classes}`} key={i}>
                            <div className="media-block__inner">
                                {icon &&
                                    icon.icon.file.url &&
                                    icon.alternateText && (
                                        <div className="media-block-icon__wrapper">
                                            <img
                                                src={icon.icon.file.url}
                                                className="media-block-icon"
                                                alt={icon.alternateText}
                                                loading="lazy"
                                            />
                                        </div>
                                    )}
                                <h3 className="media-block-headline">{headline}</h3>
                                {content &&
                                    <TextBlock
                                        className="media-block-paragraph"
                                        contentful_id={contentful_id}
                                        dataQeId="media-block"
                                        {...content}
                                        {...attributes}
                                    />
                                }
                                {cta && (
                                    <CTAButton
                                        parent={parent}
                                        dataQeId="media-blocks"
                                        {...cta}
                                        {...attributes}
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            {(attribution || ctaButtonOrCtaButtonWrapper) && (
                <div className="media-block__footer">
                    {ctaButtonOrCtaButtonWrapper && ctaButtonOrCtaButtonWrapper.hasOwnProperty('ctaButtons') &&
                        <CTAButtonWrapper
                            {...ctaButtonOrCtaButtonWrapper}
                            {...attributes}
                            dataQeId="media-block"
                        />
                    }
                    {ctaButtonOrCtaButtonWrapper && ctaButtonOrCtaButtonWrapper.hasOwnProperty('buttonText') &&
                        <CTAButton
                            {...ctaButtonOrCtaButtonWrapper}
                            {...attributes}
                            dataQeId="media-block"
                        />
                    }
                    {attribution && (
                        <p className="media-block__attribution">{attribution}</p>
                    )}
                </div>
            )}
        </div>
    );
};

MediaBlockWrapper.propTypes = {
    mediaBlock: PropTypes.arrayOf(
        PropTypes.shape({
            cta: PropTypes.object,
            headline: PropTypes.string,
            icon: PropTypes.object,
            paragraph: PropTypes.string,
        })
    )
};

MediaBlockWrapper.defaultProps = {};

export default MediaBlockWrapper;
