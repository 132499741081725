import React from 'react';
import PropTypes from 'prop-types';

const DownloadButton = props => {
    const { width, height } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 22 21">
            <path
                fill="#FFF"
                fillRule="evenodd"
                d="M22 18v3H0v-3h22zM11 .685a1.5 1.5 0 011.493 1.355l.007.145v8.694l3.563-2.85.125-.09a1.5 1.5 0 011.864 2.33l-.115.102-6 4.8-.12.088a1.5 1.5 0 01-1.633 0l-.121-.088-6-4.8-.115-.101a1.5 1.5 0 011.864-2.331l.125.09 3.563 2.85V2.185a1.5 1.5 0 011.5-1.5z"
            />
        </svg>
    );
};

DownloadButton.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

DownloadButton.defaultProps = {
    width: 22,
    height: 21,
};

export default DownloadButton;
