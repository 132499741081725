import React from 'react';
import PropTypes from 'prop-types';
import './breadcrumbs.scss';
import Link from '../link';
import BreadcrumbDropdown from './breadcrumb-dropdown';
import debounce from 'lodash/debounce';

class Breadcrumbs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: false
        };

        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.delayedHandleWindowResize = debounce(this.handleWindowResize, 150);
    }

    handleWindowResize = () => {
        this.setState({
            isMobile: window.innerWidth < 768
        });
    }

    displayDesktopContent = () => {
        const {
            breadcrumb,
            ...attributes
        } = this.props;

        return (
            breadcrumb.map((crumb, i) => {
                const {
                    text,
                    url,
                    contentful_id,
                    dropdown,
                    id,
                } = crumb,
                parent = {
                    contentful_id,
                    id
                };

                if (i !== breadcrumb.length -1) {
                    return (
                        <li key={i}>
                            <Link
                                {...attributes}
                                text={text}
                                url={url}
                                parent={parent}
                                className="breadcrumbs__text"
                                dataQeId="hero-breadcrumb"
                            />
                            {dropdown && (
                                <BreadcrumbDropdown {...dropdown} {...attributes} parent={parent} />
                            )}
                            <span className="breadcrumbs__divider">&gt;</span>
                        </li>
                    )
                } else {
                    return (
                        <li key={i}>
                            <span className="breadcrumbs__current">{text}</span>
                        </li>
                    )
                }
            })
        )
    };

    displayMobileContent = () => {
        const secondToLastCrumb = this.props.breadcrumb[this.props.breadcrumb.length - 2],
            {
                breadcrumb,
                ...attributes
            } = this.props,
            parent = {contentful_id: secondToLastCrumb.contentful_id};

        return (
            <li>
                <span className="breadcrumbs__divider">&gt;</span>
                <Link
                    {...attributes}
                    text={secondToLastCrumb.text}
                    url={secondToLastCrumb.url}
                    parent={parent}
                    className="breadcrumbs__text"
                    dataQeId="hero-breadcrumb"
                />
                {secondToLastCrumb.dropdown && (
                    <BreadcrumbDropdown {...secondToLastCrumb.dropdown} {...attributes} parent={parent} />
                )}
            </li>
        )
    };

    componentDidMount() {
        this.handleWindowResize();
        window.addEventListener('resize', this.handleWindowResize.bind(this));
    }

    render() {
        const { isMobile } = this.state,
                {breadcrumb} = this.props;

        return (
            (breadcrumb !== null) && (
                <ul className="breadcrumbs">
                    {isMobile ? this.displayMobileContent() : this.displayDesktopContent()}
                </ul>
            )
        );
    }
};

Breadcrumbs.propTypes = {
    breadcrumb: PropTypes.array
};

export default Breadcrumbs;
