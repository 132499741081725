import React, { useEffect, useState } from 'react';
import * as Components from '../components/components';
import Cookies from 'universal-cookie';
import '../assets/scss/master/master.scss';

import Banner from './banner/';
import Header from './header/';
import Section from './section/';
import ConditionalWrapper from './conditional-wrapper/';
import Footer from './footer/';
import Html from './html';

const Layout = props => {

    const cookies = new Cookies(),
        ctctLocalePrefCookieVal = cookies.get('ctct_locale_pref') || 'en_US_USD',
        ctctGeolocatedCountryCode = cookies.get('ctct-geolocated-country-code') || 'us',
        { footer, header, section, location, banner, html } = props,
        [bgColor, setBgColor] = useState(null),
        [height, setHeight] = useState(0);

    const sectionCallback = (color) => {
        setBgColor(color);
    }

    const topBannerHeightCallback = (height) => {
        setHeight(height);
    };

    const ConditionalWrappingElement = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

    return (
        <React.Fragment>
            <a href="#main" className="screen-reader-text">Skip to main content</a>
            <ConditionalWrappingElement
                condition={header && footer}
                wrapper={children => (
                    <div className="main-grid">
                        {children}
                    </div>
                )}
            >
                {header &&
                    <Header {...header} height={height}>
                        {banner && <Banner {...banner} heightCallback={topBannerHeightCallback}/>}
                    </Header>
                }
                <main role="main" className="main" id="main">
                    {section && section.map((sectionProps, i) => {

                        // TODO: Follow up on conditioanlly rendering sections
                        // See CTCTFOS-17571
                        if (sectionProps.hasOwnProperty('conditionalKey')) {
                            return <ConditionalWrapper key={i} {...sectionProps} />;
                        }

                        if (sectionProps.hasOwnProperty('sectionName')) {
                            const {
                                    backgroundColor,
                                    contentful_id,
                                    components,
                                    id,
                                    sectionName,
                                    sectionTopSpacing,
                                    sectionBottomSpacing
                                } = sectionProps;

                            return (
                                <Section
                                    backgroundColor={bgColor || backgroundColor}
                                    sectionName={sectionName}
                                    sectionTopSpacing={sectionTopSpacing}
                                    sectionBottomSpacing={sectionBottomSpacing}
                                    key={i}
                                >
                                    {components && components.map((singleComponent, i) => {
                                        const type = singleComponent.__typename !== undefined ? '__typename' : '_type';
                                        const contentfulComponentName = (singleComponent[type] !== null) ? singleComponent[type].split('Contentful').pop() : '';
                                        const module = Object.keys(Components.default).filter(component => component.toLowerCase() === contentfulComponentName.toLowerCase());

                                        if (!module.length) {
                                            return false;
                                        }

                                        return React.createElement(
                                            Components.default[module], {
                                                ...singleComponent,
                                                section: {
                                                    background: backgroundColor,
                                                    contentful_id,
                                                    id,
                                                    name: sectionName
                                                },
                                                key: i,
                                                sectionCallback: sectionCallback,
                                                ctctLocalePref: ctctLocalePrefCookieVal
                                            });
                                    })}
                                </Section>
                            );
                        }

                        return false;
                    })}
                </main>
                {footer &&
                    <Footer
                        ctctLocalePref={ctctLocalePrefCookieVal}
                        location={location}
                        {...footer}
                    />
                }
            </ConditionalWrappingElement>
            {html &&
                html.map((markup, i) => {
                    return <Html {...markup} key={i} />
                })
            }
        </React.Fragment>
    );
};

export default Layout;
