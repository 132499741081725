import React from 'react';
import PropTypes from 'prop-types';
import './tinymce-rich-text-block.scss';

const TinyMCERichTextBlock = props => {
    let tinyContent;

    tinyContent = props.content.internal ? props.content.internal.content : props.content;

    return (
        <div className="tinymce-text-block">
            {tinyContent && (
                <div dangerouslySetInnerHTML={{__html: tinyContent}} />
            )}
        </div>
    );
};

TinyMCERichTextBlock.propTypes = {
    tinyContent: PropTypes.string
};

export default TinyMCERichTextBlock;
