import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './pricing-table.scss';
import { pricingModel } from './pricing-data.js';
import { KebabCase } from '../../assets/scripts/helpers';

const PricingTable = props => {
    const {
        pricing,
        planType,
        currencySymbol
    } = props,
    [plans, setPlans] = useState(typeof planType === 'string' ? [planType] : planType);

    useEffect(() => {
        if (typeof planType === 'string') {
            setPlans([planType]);
        } else {
            setPlans(planType);
        }
    }, [planType]);

    return (
        <div className="pricing-table__wrapper">
            <h5>Tiered Pricing</h5>
            <div className="pricing-table">
                <div className="pricing-table__header">
                    <div className="pricing-table__column">
                        <h3>Contacts</h3>
                    </div>
                    {plans.map((plan, i) => {
                        return (
                            <div key={i} className="pricing-table__column">
                                <h3>{plan}</h3>
                            </div>
                        );
                    })}
                </div>
                {pricingModel.list_size_tiers.map((tier, i) => {
                    return (
                        <div
                            className="pricing-table__row small"
                            key={i}
                        >
                            <div className={`pricing-table__column pricing-table__column--contacts ${plans.length > 1 && `pricing-table__column--width-${plans.length}`}`}>
                                {tier.list_size}
                            </div>
                            {plans.map((plan, i) => {
                                return (
                                    <div key={i} className={`pricing-table__column pricing-table__column--price ${plans.length > 1 && `pricing-table__column--width-${plans.length}`}`}>
                                        {plan === 'Email Plus' ? `${currencySymbol}${tier.package_prices[pricing][KebabCase(plan)]}/mo.` : plan === 'Email' && tier.min < 10001 ? `${currencySymbol}${tier.package_prices[pricing][KebabCase(plan)]}/mo.` : '-'}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                <div className="pricing-table__row small">
                    <div className={`pricing-table__column pricing-table__column--contacts ${plans.length > 1 && `pricing-table__column--width-${plans.length}`}`}>
                        50,000+
                    </div>
                    <div className={`pricing-table__column pricing-table__column--price ${plans.length > 1 && `pricing-table__column--span-${plans.length}`}`}>
                        <strong>List size over 50,000?<br/>Call us at <a href="tel:855-783-2308">855-783-2308</a></strong>
                    </div>
                </div>
            </div>
        </div>
    )
};

PricingTable.propTypes = {
    pricing: PropTypes.string.isRequired,
    planType: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string
    ]).isRequired
};

export default PricingTable;
