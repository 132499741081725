import React, { useState, useRef, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import Velocity from 'velocity-react/lib/velocity-animate-shim';
import CTAButton from '../cta-button/';
import Image from '../image';
import Video from '../video-block';
import TextBlock from '../text-block';
import './dynamic-feature-block.scss';

const DynamicFeatureBlockWrapper = props => {
    const {
        contentful_id: parentContentfulId,
        id: parentId,
        dynamicFeatureBlockTabs,
        ...attributes
    } = props,
    parent = {
        contentful_id: parentContentfulId,
        id: parentId
    },
    [ tab, setTab ] = useState({
        activeStyle: {},
        navPaddlesVisible: false
    }),
    {
        activeStyle,
        navPaddlesVisible,
    } = tab,
    [activeTab, setActiveTab] = useState(0),
    content = useRef(null),
    navContainer = useRef(null);

    useEffect(() => {
        const updateTab = () => {
            setTab({
                activeStyle: {
                    transform: `translate3d(${navContainer.current.children[activeTab].offsetLeft}px, 0, 0)`,
                    width: `${navContainer.current.children[activeTab].offsetWidth}px`
                },
                navPaddlesVisible: ((navContainer.current.scrollWidth > 920) || (navContainer.current.scrollWidth > window.innerWidth))
            });
        }

        updateTab()

        /*
            Set the height of the content on start.
            Needs a setTimeout to get an accurate height
        */
        setTimeout(() => {
            onEnter(content.current);
            onEntered(content.current);
        }, 50);

        if (window !== 'undefined') {

            function handleWindowResize() {
                updateTab();
                animateNav(navContainer.current.children[activeTab]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        }
        // pass activeTab to useEffect so that useEffect gets the
        // current activeTab every time handleWindowResize is fired
    }, [activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

    const animateNav = (tab) => {
        if (navPaddlesVisible) {
            Velocity(
                navContainer.current.children[0],
                'scroll', {
                    axis: 'x',
                    duration: 500,
                    container: navContainer.current,
                    offset: tab.offsetLeft
                }
            )
        }
    }

    const onTabClick = (e) => {
        let selectedTab;

        if (e.currentTarget.classList.contains('tab-paddle')) {
            let nextTab = e.currentTarget.classList.contains('tab-paddle-right') ? activeTab + 1 : activeTab -1;

            setActiveTab(nextTab);

            selectedTab = navContainer.current.children[nextTab];
        } else {
            selectedTab = e.currentTarget.parentNode;
        }

        setTab({
            ...tab,
            activeStyle: {
                transform: `translate3d(${selectedTab.offsetLeft}px, 0, 0)`,
                width: `${selectedTab.offsetWidth}px`
            }
        });

        animateNav(selectedTab);
    }

    function onExit(el) {
        if (el) {
            el.parentNode.style.minHeight = el.parentNode.getAttribute('data-to-height');
        }
    }

    function onEnter(el) {
        if (el) {
            const height = el.clientHeight;
            el.parentNode.setAttribute('data-to-height', `${height}px`);
        }
    }

    function onEntered(el) {
        if (el) {
            const height = el.clientHeight;
            el.parentNode.style.minHeight = `${height}px`;
        }
    }

    return (
        <div
            className="dynamic-feature-block"
        >
            <div className="container container--dynamic-feature-block">
                <div className="dynamic-feature-block__nav">
                    <ul
                        ref={navContainer}
                        style={{display: navPaddlesVisible ? 'block' : 'inline-block'}}
                    >
                        {dynamicFeatureBlockTabs.map((data, i) => {
                            const {
                                contentful_id,
                                heading
                            } = data;

                            return (
                                <li
                                    key={i}
                                    className={`${activeTab === i ? 'active' : ''}`}
                                >
                                    <CTAButton
                                        {...attributes}
                                        buttonText={heading}
                                        dataQeId="dynamic-feature-block-tab"
                                        onClick={(e) => {setActiveTab(i); onTabClick(e)}}
                                        parent={parent}
                                        type="button"
                                        contentful_id={contentful_id}
                                    />
                                </li>
                            );
                        })}
                        <li className="tab-slider" style={activeStyle}></li>
                    </ul>
                    {navPaddlesVisible ?
                        <div className="tab-paddles">
                            {(activeTab !== 0) &&
                                <CTAButton
                                    {...attributes}
                                    className="tab-paddle tab-paddle-left"
                                    dataQeId="tab-paddle-left"
                                    eventActionText="Previous tab"
                                    aria-hidden="true"
                                    onClick={(e) => {onTabClick(e)}}
                                    parent={{contentful_id: 'prv'}}
                                    type="button"
                                >
                                    <svg width="10" height="18" viewBox="20.00025177001953 17.000032424926758 9.999565124511719 16.59514045715332" preserveAspectRatio="xMinYMin meet">
                                      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                                        <path d="M26.27 32.067l-6.816-6.72c-.605-.588-.605-1.51 0-2.107l6.815-6.72c.606-.597 1.459-.733 2.064-.127l.257.244c.477.543.587 1.176.064 1.647-1.678 1.655-3.348 3.31-5.026 4.965a1.455 1.455 0 0 0 0 2.098c1.678 1.655 3.348 3.31 5.026 4.957.523.48.413 1.112-.064 1.646l-.257.253c-.605.606-1.458.47-2.064-.136" fill="#757677"></path>
                                      </g>
                                    </svg>
                                </CTAButton>
                            }
                            {(activeTab !== dynamicFeatureBlockTabs.length -1) &&
                                <CTAButton
                                    {...attributes}
                                    className="tab-paddle tab-paddle-right"
                                    dataQeId="tab-paddle-right"
                                    eventActionText="Next tab"
                                    aria-hidden="true"
                                    onClick={(e) => {onTabClick(e)}}
                                    parent={{contentful_id: 'nxt'}}
                                    type="button"
                                >
                                    <svg width="10" height="18" transform="scale(-1,1)" viewBox="20.00025177001953 17.000032424926758 9.999565124511719 16.59514045715332" preserveAspectRatio="xMinYMin meet">
                                      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                                        <path d="M26.27 32.067l-6.816-6.72c-.605-.588-.605-1.51 0-2.107l6.815-6.72c.606-.597 1.459-.733 2.064-.127l.257.244c.477.543.587 1.176.064 1.647-1.678 1.655-3.348 3.31-5.026 4.965a1.455 1.455 0 0 0 0 2.098c1.678 1.655 3.348 3.31 5.026 4.957.523.48.413 1.112-.064 1.646l-.257.253c-.605.606-1.458.47-2.064-.136" fill="#757677"></path>
                                      </g>
                                    </svg>
                                </CTAButton>
                            }
                        </div>
                        : ''
                    }
                </div>
                <div className="dynamic-feature-block__content-container">
                    <TransitionGroup component={null}>
                        <CSSTransition
                            key={activeTab}
                            classNames={{
                                enterActive: 'animated',
                                exitActive: 'animated',
                                enter: 'fadeIn fast',
                                exit: 'fadeOut fast',
                            }}
                            onEnter={onEnter}
                            onEntered={onEntered}
                            onExit={onExit}
                            timeout={1000}
                        >
                            <div
                                className="dynamic-feature-block__content"
                                ref={content}
                            >
                                {dynamicFeatureBlockTabs.map((data, i) => {
                                    const {
                                        cta,
                                        content
                                    } = data;

                                    if (activeTab !== i) {
                                        return false;
                                    }

                                    return (
                                        <React.Fragment key={i}>
                                            {activeTab === i && content &&
                                                <React.Fragment>
                                                    <TextBlock
                                                        {...content}
                                                        {...attributes}
                                                        contentful_id={data.contentful_id}
                                                        dataQeId="dynamic-feature-block"
                                                    />
                                                    {cta &&
                                                        <CTAButton
                                                            {...cta}
                                                            {...attributes}
                                                            parent={parent}
                                                            dataQeId="dynamic-feature-block"
                                                        />
                                                    }
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        </div>
    );
}

DynamicFeatureBlockWrapper.propTypes = {
    dynamicFeatureBlockTabs: PropTypes.arrayOf(PropTypes.shape({
        heading: PropTypes.string,
        image: PropTypes.object,
        videoSource: PropTypes.object,
        content: PropTypes.object,
        cta: PropTypes.object
    }))
};

DynamicFeatureBlockWrapper.defaultProps = {};

export default DynamicFeatureBlockWrapper;
