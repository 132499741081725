import Banner from './banner/banner';
import BreadcrumbWrapper from './breadcrumbs/breadcrumbs-view';
import CardWrapper from './card/card-view';
import CenteredCta from './centered-cta/centered-cta-view';
import CheckboxTextWrapper from './checkbox-text/checkbox-text-view';
import ConditionalWrapper from './conditional-wrapper/conditional-wrapper';
import CtaButton from './cta-button/cta-button-view';
import CtaButtonWrapper from './cta-button-wrapper/cta-button-wrapper-view';
import DynamicFeatureBlockWrapper from './dynamic-feature-block/dynamic-feature-block-view';
import FullImageCardWrapper from './full-image-card/full-image-card-view';
import Hero from './hero/hero-view';
import Html from './html/html';
import Input from './input/input';
import Image from './image/image-view';
import LogoWrapper from './logo/logo-view';
import MediaBlockWrapper from './media-block/media-block-view';
import Modal from './modal/modal-view';
import OneColumnTextBlock from './one-column-text-block/one-column-text-block-view';
import RoiCalculator from './roi-calculator/roi-calculator-view';
import Section from './section/section-view';
import Select from './select/select';
import SubFeaturesBlock from './sub-features-block/sub-features-block-view';
import Textarea from './textarea/textarea';
import TinyMCERichTextBlock from './tinymce-rich-text-block/tinymce-rich-text-block-view';
import TwoColumnBlock from './two-column-block/two-column-block-view';
import TwoColumnTextBlock from './two-column-text-block/two-column-text-block-view';
import TwoStepSignupForm from './two-step-signup-form/two-step-signup-form-view';
import Video from './video-block/video-block-view';

export default {
    Banner,
    BreadcrumbWrapper,
    CardWrapper,
    CenteredCta,
    CheckboxTextWrapper,
    ConditionalWrapper,
    CtaButton,
    CtaButtonWrapper,
    DynamicFeatureBlockWrapper,
    FullImageCardWrapper,
    Hero,
    Html,
    Image,
    Input,
    LogoWrapper,
    MediaBlockWrapper,
    Modal,
    OneColumnTextBlock,
    RoiCalculator,
    Section,
    Select,
    SubFeaturesBlock,
    Textarea,
    TinyMCERichTextBlock,
    TwoColumnBlock,
    TwoColumnTextBlock,
    TwoStepSignupForm,
    Video
}
