import React, { useState, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from '../input';
import Select from '../select';
import CtaButton from '../cta-button';
import { StateToState } from '../../assets/scripts/helpers';
import './roi-calculator.scss';
import { contactData } from './contact-data.js';
import { industryData } from './industry-data.js';
import { salesData } from './sales-data.js';
import Cookies from 'universal-cookie';

const RoiCalculator = props => {
    const {
        id,
        contentful_id,
        ...attributes
    } = props,
    [ cost, setMonthlyCost ] = useState('0'),
    [ roi, setRoi ] = useState('0'),
    [ industry, setIndustry ] = useState(''),
    [ sales, setSales ] = useState(''),
    [ contacts, setContacts ] = useState(''),
    [ callMessage, setCallMessage ] = useState(false);

    let introContent,
        middleContent,
        bottomContent;

    introContent = props.introContent && props.introContent.internal ? props.introContent.internal.content : props.introContent;
    middleContent = props.middleContent && props.middleContent.internal ? props.middleContent.internal.content : props.middleContent;
    bottomContent = props.bottomContent && props.bottomContent.internal ? props.bottomContent.internal.content : props.bottomContent;

    useLayoutEffect(() => {
            fireUtag();
    }, [roi]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        calculateRoi();
        checkCallMessage();
    }, [contacts, industry, sales]); // eslint-disable-line react-hooks/exhaustive-deps

    const createUrl = () => {
        fireUtag()

        const cookies = new Cookies();

        if (window !== 'undefined') {
            const campaignCode = cookies.get('cclp_session_cc') ? `&tfa_37=${cookies.get('cclp_session_cc')}` : "",
                url = `?tfa_1182=${window.location.href}`,
                soid = cookies.get('sops') ? `&id=${cookies.get('sops').split("#")[0].substr(1)}` : "",
                geoLoc = cookies.get('ctct-geolocated-state-code') ? `&tfa_859=${StateToState(cookies.get('ctct-geolocated-state-code'))}` : "",
                contactEntry = contacts.length > 0 ? `&tfa_1191=${contacts}` : "",
                salesEntry = sales.length > 0 ? `&tfa_1192=${sales}` : "",
                industryEntry = industry.length > 0 ? `&tfa_1193=${industry}` : "",
                roiCalc = roi.length > 0 ? `&tfa_1194=${roi}` : "";

            window.location.href = `https://constantcontact.tfaforms.net/647${url}${campaignCode}${soid}${geoLoc}${contactEntry}${salesEntry}${industryEntry}${roiCalc}`;
        }
    };

    const fireUtag = () => {
        if (typeof window !== 'undefined' && window.utag) {
            window.utag.link({
                tealium_event:'link',
                ga_event_category: 'roi',
                ga_event_action: 'roi-calculation',
                ga_event_link_name: 'roi-calculator',
                roicalc_sales: sales,
                roicalc_est_monthly_roi: contacts < 2500 ? 'call_us' : roi
            });
        }
    };

    const numberWithCommas = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const calculateRoi = () => {
        if (contacts && sales && industry) {
            let industryRevenue = industryData[industry][sales];

            setRoi(numberWithCommas(Number(cost.replace(/,/g,'')) * industryRevenue));
        }
    };

    const updateContacts = selectedInput => {
        if (selectedInput === "") {
            setMonthlyCost("0");
            setRoi("0");
        };

        for (let i=0 ; i < Object.keys(contactData).length; i++) {
            //check if the input is only 0's
            if(/^0*$/.test(selectedInput)) {
                return;
            };

            if (parseInt(selectedInput) <= Object.keys(contactData)[i]) {
                return setMonthlyCost(numberWithCommas(contactData[Object.keys(contactData)[i]]));
            }
        }
    };

    const checkCallMessage = () => {
        (contacts > 200000 || (contacts < 2500 && contacts !== "") || sales === "$50MM+") ? setCallMessage(true) : setCallMessage(false);
    };

    const setInputVals = e => {
        let selectedInput = e.target.value;

        if (e.target.id === "roi-industry-dropdown") {
            setIndustry(selectedInput);
        } else if (e.target.id === "roi-contact-input") {
            setContacts(selectedInput);
            updateContacts(selectedInput);
        } else {
            setSales(selectedInput);
        }
    };

    return (
        <React.Fragment>
            {introContent && (
                <div className="roi-modal__intro">
                    <div dangerouslySetInnerHTML={{__html: introContent}} />
                </div>
            )}
            <div className="roi-modal__body form">
                <Input
                    id="roi-contacts"
                    inputValue={contacts}
                    dataQeId="roi-contact-input"
                    inputName="roi-contact-input"
                    inputType="number"
                    inputLabel="Number of email contacts"
                    onChangeCallback={setInputVals}
                    min="1"
                />
                <Select
                    dataQeId="roi-contact-dropdown"
                    selectHiddenOption=" "
                    selectName="roi-contact-dropdown"
                    selectLabel="Annual ecommerce sales"
                    selectOptions={salesData}
                    onChangeCallback={setInputVals}
                />
                <Select
                    dataQeId="roi-industry-dropdown"
                    selectHiddenOption=" "
                    selectName="roi-industry-dropdown"
                    selectLabel="Industry"
                    selectOptions={Object.keys(industryData)}
                    onChangeCallback={setInputVals}
                />
                <div className={`roi-modal__prices${roi.length > 9 ? ' tiny-nums' : roi.length > 6 ? ' small-nums' : ''}`}>
                    {callMessage ?
                        <h2 className="call-message">
                            Call us at <a href="tel:1-877-540-1758"><nobr>1-877-540-1758</nobr></a> for a quote.
                        </h2> :
                        <React.Fragment>
                            <div className="roi-modal__prices__block roi-modal__prices__block--left">
                                <p className="total">{`$${cost}`}</p>
                                <p className="total__note">Monthly Ecommerce Pro Cost*</p>
                            </div>
                            <div className="roi-modal__prices__block roi-modal__prices__block--right">
                                <p className="total">{`$${roi}`}</p>
                                <p className="total__note">Estimated Monthly ROI*</p>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
            <div className="roi-modal__content">
                {middleContent && (
                    <div dangerouslySetInnerHTML={{__html: middleContent}} />
                )}
                <CtaButton
                    contentful_id="rdb"
                    buttonText="Request a demo"
                    color="orange"
                    dataQeId="roi-request-demo-btn"
                    parent={{contentful_id: 'rmf'}}
                    section={{contentful_id: 'roi-modal-footer'}}
                    target="Same Window"
                    size="large"
                    type="button"
                    disabled={attributes.disabled}
                    onClick={createUrl}
                />
            </div>
            {bottomContent && (
                <div className="roi-modal__footer">
                    <div dangerouslySetInnerHTML={{__html: bottomContent}} />
                </div>
            )}
        </React.Fragment>
    );
};

RoiCalculator.propTypes = {
    introContent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    bottomContent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
};

export default RoiCalculator;
