import React from 'react';
import PropTypes from 'prop-types';
import Link from '../link';

const LinkList = props => {

    const {
        headline,
        links,
        parent,
        section
    } = props;

    return (
        <section className="link-list">
            <div className="container container--link-list">
                <div className="sub-features__header">
                    <h2>{headline}</h2>
                </div>
                <div className="link-list__body">
                    {links.map((link, i) => {

                        const {url, linkText, contentful_id} = link,
                        linkId = {contentful_id: contentful_id};

                        return (
                            <div className="link-list__link-content" key={i}>
                                <Link
                                    text=''
                                    url={url}
                                    parent={parent}
                                    link={linkId}
                                    section={section}
                                    dataQeId="hero-breadcrumb-dropdown"
                                >
                                    <span>{linkText}</span>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    );
}

LinkList.propTypes = {
    headline: PropTypes.string,
    links: PropTypes.array,
    parent: PropTypes.object,
    section: PropTypes.object
};

export default LinkList;
