import React from 'react';
import PropTypes from 'prop-types';
import './sub-features-block.scss';
import SubFeature from './sub-features-view';

const SubFeaturesBlock = (props) => {
    const { headline, subFeatures, ...attributes} = props;

    function setSubFeaturesBlockWidth() {
        if (subFeatures.length % 4 === 0) {
            return 'quarters';
        } else {
            return 'thirds';
        }
    }

    return (
        <div className="sub-features">
            {headline && (
                <div className="sub-features__header">
                    <h2>{headline}</h2>
                </div>
            )}
            <div className={`sub-features__body sub-features__body--${setSubFeaturesBlockWidth()}`}>
                {subFeatures.map((data, i) => (
                    <SubFeature {...data} {...attributes} key={i} />
                ))}
            </div>
        </div>
    );
}

SubFeaturesBlock.propTypes = {
    headline: PropTypes.string,
    subFeatures: PropTypes.array
};

export default SubFeaturesBlock;
