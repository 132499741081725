import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VelocityBandaid from '../velocity-bandaid';
import CtaButton from '../cta-button';

const SubFeaturesView = props => {
    const { contentful_id, headline, id, links, ...attributes } = props,
        parent = {
            contentful_id,
            id,
        },
        [isMobile, setMobile] = useState(false),
        [isOpen, setOpen] = useState(false);

    useEffect(() => {
        if (window !== 'undefined') {
            handleWindowResize();

            window.addEventListener('resize', handleWindowResize);

            function handleWindowResize() {
                setMobile(window.innerWidth < 1024);
            }

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        }
    }, []);

    return (
        <div
            className={`sub-features__feature${isOpen ? ' sub-features__feature--open' : ''}${headline ? '' : ` sub-features__feature--no-headline`}`}
        >
            {headline && isMobile ? (
                <CtaButton
                    {...attributes}
                    className="sub-features__feature-title"
                    dataQeId="sub-feature-title"
                    eventActionText={headline}
                    onClick={() => {setOpen(!isOpen)}}
                    parent={parent}
                    contentful_id="acc"
                    type="button"
                >
                    <span>{headline}</span>
                    <svg className="sub-features__icon" width="12" height="7">
                        <path
                            d="M1 1.1L2.056 0l4.169 4.343L10.395 0l1.055 1.1-5.212 5.431-.013-.014-.013.014z"
                            fill="#FFF"
                            fillRule="nonzero"
                            stroke="#FFF"
                        />
                    </svg>
                </CtaButton>
            ) : (
                headline && (
                    <h5>{headline}</h5>
                )
            )}
            <VelocityBandaid
                animation={!isOpen ? 'slideUp' : 'slideDown'}
                duration={500}
            >
                <div className="sub-features__feature-content">
                    {links.map((link, i) => (
                        <a
                            href={link.url}
                            data-tracked-action={link.dataTrackedAction}
                            key={i}
                        >
                            <span>{link.linkText}</span>
                        </a>
                    ))}
                </div>
            </VelocityBandaid>
        </div>
    );
};

SubFeaturesView.propTypes = {
    headline: PropTypes.string,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            dataTrackedAction: PropTypes.string,
            linkText: PropTypes.string,
        })
    ),
};

export default SubFeaturesView;
