import React, { useState, useEffect, useRef } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import Flickity from 'react-flickity-component';
import Velocity from 'velocity-react/lib/velocity-animate-shim';
import TextBlock from '../text-block';
import Video from '../video-block';
import CtaButton from '../cta-button';
import Image from '../image';
import { KebabCase } from '../../assets/scripts/helpers';

import './full-image-card.scss';

const flickityOptions = {
    initialIndex: 0,
    cellAlign: 'left',
    groupCells: true,
    lazyLoad: true,
    pageDots: false,
    percentPosition: false,
    prevNextButtons: false,
    wrapAround: true,
    resize: false,
    selectedAttraction: 0.033,
    friction: 0.33
};

const FullImageCardWrapper = props => {

    const {
        contentful_id,
        headline,
        id,
        fullImageCards,
        ...attributes
    } = props,
    [imageCards, setImageCards] = useState({
        overlayOpen: false,
        hasLink: false,
        hasLinkText: false
    }),
    [selectedIndex, setSelectedIndex] = useState(0),
    [windowSize, setWindowSize] = useState({
        window: {
            width: (typeof window !== 'undefined') ? window.innerWidth : 0,
            height: (typeof window !== 'undefined') ? window.innerHeight : 0,
        }
    }), {
        overlayOpen,
        hasLink, // eslint-disable-line no-unused-vars
        hasLinkText, // eslint-disable-line no-unused-vars
    } = imageCards, {
        window: {
            width: winW,
            height: winH,
        }
    } = windowSize,
    flkty = useRef(null),
    overlay = useRef(null),
    sectionBackground = attributes.section.background,
    overlayCards= [];

    const openOverlay = i => {

        overlay.current.classList.add('two-column-carousel--open');

        Velocity(
            overlay.current, {
                clipTop: '0px'
            }, {
                duration: 300,
                easing: 'easeInOutQuad'
            }
        );

        if (typeof window !== 'undefined') {
            setWindowSize({
                window: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                }
            });
        }

        flkty.current.selectCell(i, false, true);

        /*
            Dumb flickity can't get the correct offset
            of the first slide so it sets the carousel's
            translateX to the maximum flkty.current.slideableWidth
        */
        if (flkty.current.x === -0) {
            flkty.current.slider.style.transform = 'translateX(0px)';
        }

        setSelectedIndex(i + 1);

        setImageCards({
            ...imageCards,
            overlayOpen: true,
        });

        document.documentElement.classList.add('no-scroll');
    };

    const closeOverlay = () => {

        overlay.current.classList.remove('two-column-carousel--open');

        Velocity(
            overlay.current, {
                clipTop: `${winH}px`
            }, {
                duration: 300,
                easing: 'easeInOutQuad'
            }
        );

        setImageCards({
            ...imageCards,
            overlayOpen: false,
        });

        document.documentElement.classList.remove('no-scroll');
    };

    useEffect(() => {

        const onKeyDown = (e) => {
            const code = e.keyCode;

            switch (code) {
                case 27: // Esc
                    closeOverlay();
                    break;
                case 37: // Left
                    if (overlayOpen) {
                        flkty.current.previous();
                    }
                    break;
                case 39: // Right
                    if (overlayOpen) {
                        flkty.current.next();
                    }
                    break;
                default:
            }
        }

        const onResize = () => {
            document.documentElement.classList.add('resize-animation-stopper');

            if (overlay && typeof window !== 'undefined') {
                setWindowSize({
                    window: {
                        width: window.innerWidth,
                        height: window.innerHeight
                    }
                });

                const isOpen = overlay.current.classList.contains('two-column-carousel--open');
                overlay.current.style.clip = `rect(${isOpen ? `0px` : `${window.innerHeight + 1}px`} ${window.innerWidth + 1}px ${window.innerHeight + 1}px 0px)`;
            }

            flkty.current.resize();

            document.documentElement.classList.remove('resize-animation-stopper');
        }

        const parallaxCarousel = () => {
            const docStyle = document.documentElement.style,
                transformProp = typeof docStyle.transform == 'string' ?
                'transform' :
                'WebkitTransform';

            flkty.current.cells.forEach((slide, i) => {
                const img = slide.element.querySelector('.video__poster img'),
                    x = (slide.target + flkty.current.x) * -1 / 3;
                img.style[transformProp] = 'translateX(' + x + 'px)';
            });
        }

        const updateSelectedIndex = () => {
            setSelectedIndex(flkty.current.selectedIndex + 1);
        };

        // Check if any of the cards have a link
        // If they do update the state to switch all
        // CTA's to <a> tags and don't render the overlay
        if (fullImageCards.length > 0) {
            const hasLink = fullImageCards.map(card => card.ctaButton.url !== null),
                hasLinkText = fullImageCards.map(card => card.ctaButton.buttonText !== null);
            setImageCards({
                ...imageCards,
                hasLink: hasLink.indexOf(true) > -1,
                hasLinkText: hasLinkText.indexOf(true) > -1
            });
        }

        if (flkty) {
            flkty.current.on('scroll', parallaxCarousel);
            flkty.current.on('change', updateSelectedIndex);

            if (overlay && typeof window !== 'undefined') {
                overlay.current.style.clip = `rect(${winH + 1}px ${winW + 1}px ${winH + 1}px 0px)`;
                overlay.current.classList.add('two-column-carousel--ready');
                window.addEventListener('keydown', onKeyDown);
                window.addEventListener('resize', onResize);
            }

            return () => {
                flkty.current.off('scroll', parallaxCarousel);
                flkty.current.off('change', updateSelectedIndex);
            }
        }

        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('resize', onResize);
        }

    }, [overlayOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    function onExit(el) {
        el.parentNode.style.minHeight = el.parentNode.getAttribute('data-to-height');
    }

    function onEnter(el) {
        const height = el.clientHeight;
        el.parentNode.setAttribute('data-to-height', `${height}px`);
    }

    function onEntered(el) {
        const height = el.clientHeight;
        el.parentNode.style.minHeight = `${height}px`;
    }

    const showCarouselControls = size => {
        return (
            <div className={`two-column-carousel__controls two-column-carousel__controls-${size}`}>
                <CtaButton
                    {...attributes}
                    className="two-column-carousel__control two-column-carousel__control--previous"
                    dataQeId="full-image-card-prev"
                    eventActionText="Previous slide"
                    onClick={() => {
                        flkty.current.previous();
                    }}
                    parent={{contentful_id: 'prv'}}
                    type="button"
                >
                    <svg
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                    >
                        <circle
                            className="two-column-carousel__circle"
                            cx="25"
                            cy="25"
                            fill="none"
                            r="24"
                            stroke="#FFF"
                        />
                        <path
                            d="M27.27 33.067l-6.816-6.72c-.605-.588-.605-1.51 0-2.107l6.815-6.72c.606-.597 1.459-.733 2.064-.127l.257.244c.477.543.587 1.176.064 1.647-1.678 1.655-3.348 3.31-5.026 4.965a1.455 1.455 0 000 2.098c1.678 1.655 3.348 3.31 5.026 4.957.523.48.413 1.112-.064 1.646l-.257.253c-.605.606-1.458.47-2.064-.136"
                            fill="#FFF"
                        />
                    </svg>
                    <span className="screen-reader-text">Go back to the previous slide</span>
                </CtaButton>
                <span className="two-column-carousel__counter">
                    {selectedIndex} / {overlayCards.length}
                </span>
                <CtaButton
                    {...attributes}
                    className="two-column-carousel__control two-column-carousel__control--next"
                    dataQeId="full-image-card-next"
                    eventActionText="Next slide"
                    onClick={() => {
                        flkty.current.next();
                    }}
                    parent={{contentful_id: 'nxt'}}
                    type="button"
                >
                    <svg
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                    >
                        <circle
                            className="two-column-carousel__circle"
                            cx="25"
                            cy="25"
                            fill="none"
                            r="24"
                            stroke="#FFF"
                        />
                        <path
                            d="M22.73 17.528l6.816 6.72c.605.588.605 1.51 0 2.108l-6.815 6.72c-.606.596-1.459.732-2.064.126l-.257-.244c-.477-.543-.587-1.176-.064-1.646 1.678-1.655 3.348-3.31 5.026-4.966a1.455 1.455 0 000-2.098c-1.678-1.655-3.348-3.31-5.026-4.956-.523-.48-.413-1.113.064-1.646l.257-.254c.605-.606 1.458-.47 2.064.136"
                            fill="#FFF"
                        />
                    </svg>
                    <span className="screen-reader-text">Advance to the next slide</span>
                </CtaButton>
            </div>
        );
    }

    const renderButton = (card, i, cardParent) => {
        const {
            ctaButton
        } = card;

        if (ctaButton.buttonText && !ctaButton.url) {
            return (
                <CtaButton
                    {...attributes}
                    buttonText={ctaButton.buttonText}
                    className="full-image-cards__cta"
                    dataQeId={`full-image-cards-${i}`}
                    id={`image-card-cta-${i}`}
                    onFocus={() => {
                        flkty.current.selectCell(overlayCards.indexOf(i), false, true);
                    }} /* Dumb flickity fix is triggering the a11y linter */
                    onMouseOver={() => {
                        flkty.current.selectCell(overlayCards.indexOf(i), false, true);
                    }} /* Flickity is being a real turd if selectCell isn't fired twice */
                    onClick={() => openOverlay(overlayCards.indexOf(i))}
                    parent={cardParent}
                    type="button"
                />
            )
        }

        if (ctaButton.buttonText && ctaButton.url) {
            return (
                <CtaButton
                    {...attributes}
                    {...ctaButton}
                    className="full-image-cards__cta"
                    dataQeId={`full-image-cards-${i}`}
                    id={`image-card-cta-${i}`}
                    parent={cardParent}
                />
            )
        }
    }

    function changeContainerLayout() {
        if (fullImageCards.length >= 3) {
            return 'full-image-cards__body--flex-start';
        } else {
            return '';
        }
    }

    return (
        <>
            <div className="full-image-cards">
                {headline &&
                    <div className="full-image-cards__header">
                        <h2>{headline}</h2>
                    </div>
                }
                <div className={`full-image-cards__body ${changeContainerLayout()}`}>
                    {fullImageCards.map((card, i) => {
                        const {
                            contentful_id,
                            id,
                            largeText,
                            smallText,
                            cardImage,
                            ctaButton
                        } = card,
                        cardParent = {
                            contentful_id,
                            id
                        };

                        return (
                            <div
                                className={`full-image-cards__card full-image-cards__card--${KebabCase(sectionBackground)}${ctaButton.buttonText ? ' zoom-effect' : ''}`}
                                key={i}
                            >
                                <div className="full-image-cards__hover">
                                    <div className="full-image-cards__overlay"></div>
                                    <Image {...cardImage} />
                                    <div className="full-image-cards__content">
                                        <p className="full-image-cards__small-text">
                                            {smallText}
                                        </p>
                                        <p className="full-image-cards__large-text">
                                            {largeText}
                                        </p>
                                        {renderButton(card, i, cardParent)}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div
                className="two-column-carousel"
                ref={overlay}
            >
                <CtaButton
                    {...attributes}
                    className="two-column-carousel__close"
                    dataQeId="full-image-card-close"
                    eventActionText="Close overlay"
                    onClick={closeOverlay}
                    parent={{contentful_id}}
                    type="button"
                >
                    <svg width="43" height="43" viewBox="0 0 43 43">
                        <g
                            fill="none"
                            fillRule="evenodd"
                            stroke="#FFF"
                            transform="translate(1 1)"
                        >
                            <path
                                strokeLinecap="square"
                                strokeWidth="3"
                                d="M25.95 16.05l-9.9 9.9m9.9 0l-9.9-9.9"
                            />
                            <circle cx="20.5" cy="20.5" r="20.5" />
                        </g>
                    </svg>
                    <span className="screen-reader-text">Close overlay</span>
                </CtaButton>
                <div className="two-column-carousel__wrapper">
                    <div className="two-column-carousel__body">
                        <Flickity
                            className={`two-column-carousel__carousel`}
                            elementType={`div`}
                            options={flickityOptions}
                            flickityRef={c => {
                                flkty.current = c;
                            }}
                        >
                            {fullImageCards.map((card, slideIndex) => {
                                const {
                                    slideVideo
                                } = card;

                                if ((slideVideo === null) || (card.ctaButton.buttonText === null)) {
                                    return false;
                                }

                                if (card.ctaButton.url !== null) {
                                    return false;
                                }

                                overlayCards.push(slideIndex)

                                return (
                                    <div
                                        className="two-column-carousel__slide"
                                        key={slideIndex}
                                    >
                                        <div className="two-column-carousel__media">
                                            <Video
                                                {...attributes}
                                                {...slideVideo}
                                                dataQeId="full-image-card"
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </Flickity>
                        <div className="two-column-carousel__controls-wrapper">
                            {(winW < 991) && showCarouselControls('mobile')}
                        </div>
                        <div className="two-column-carousel__content-container">
                            <div className="two-column-carousel__content">
                                <TransitionGroup component={null}>
                                    <CSSTransition
                                        key={selectedIndex}
                                        classNames={{
                                            enterActive: 'animated fast',
                                            exitActive: 'animated fast',
                                            enter: 'fadeInUpBig',
                                            exit: 'fadeOutUpBig',
                                        }}
                                        onEnter={onEnter}
                                        onEntered={onEntered}
                                        onExit={onExit}
                                        timeout={800}
                                    >
                                        <div className="two-column-carousel__content-inner">
                                            {fullImageCards.map((card, contentIndex) => {

                                                if ((overlayCards.indexOf(contentIndex) + 1) !== selectedIndex) {
                                                    return false;
                                                }

                                                if (overlayCards.length === 0) {
                                                    return false;
                                                }

                                                const {
                                                    slideContent,
                                                    slideHeadline,
                                                    slideLogo,
                                                    slideLogoAlt
                                                } = card;

                                                return (
                                                    <React.Fragment key={contentIndex}>
                                                        {slideLogo && (
                                                            <img
                                                                className="two-column-carousel__logo"
                                                                src={slideLogo.file.url}
                                                                alt={slideLogoAlt}
                                                                loading="lazy"
                                                            />
                                                        )}
                                                        <h2 className="two-column-carousel__headline">{slideHeadline}</h2>
                                                        {slideContent &&
                                                            <TextBlock {...slideContent} />
                                                        }
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    </CSSTransition>
                                </TransitionGroup>
                            </div>
                            <div className="two-column-carousel__controls-wrapper">
                                {(winW > 991) && showCarouselControls('desktop')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

FullImageCardWrapper.propTypes = {
    headline: PropTypes.string,
    fullImageCards: PropTypes.arrayOf(
        PropTypes.shape({
            largeText: PropTypes.string,
            smallText: PropTypes.string,
            image: PropTypes.object,
        })
    ),
};

export default FullImageCardWrapper;
