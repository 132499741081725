import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import VelocityBandaid from '../velocity-bandaid';
import TextBlock from '../text-block';
import CtaButton from '../cta-button';
import Link from '../link';
import './footer.scss';
import {
    getPathnameBasedOnLocale
} from '../../assets/scripts/helpers'

const FooterView = props => {
    let timeout = null;
    const {
        contentful_id,
        footerLinks,
        footerType,
        id,
        location,
        subHeadingRichText,
        visibility,
    } = props,
    [footer, setFooter] = useState({
        active: [],
    }),
    {
        active,
    } = footer,
    localeMenu = useRef(null),
    section = {
        contentful_id,
        id
    };

    let pathname = '';

    if (location !== undefined) {
        pathname = location.pathname;
    }

    let pathnameBasedOnGlobalLocale = getPathnameBasedOnLocale(pathname, 'en');

    // remove trailing slash from non-root pathname
    if (pathnameBasedOnGlobalLocale !== '/' && pathnameBasedOnGlobalLocale.endsWith('/')) {
        pathnameBasedOnGlobalLocale = pathnameBasedOnGlobalLocale.substring(0, pathnameBasedOnGlobalLocale.length - 1);
    }

    useEffect(() => {
        const onKeyDown = (e) => {
            const code = e.keyCode;

            switch(code) {
                case 27: // Esc
                    setFooter({...footer, localeOpen: false});
                    break;
                default:
            }
        }

        // Click away
        const onMouseDown = (e) => {

            if (localeMenu.current === null || localeMenu.current.contains(e.target)) {
                return;
            }

            setFooter({...footer, localeOpen: false});
        }

        if (window) {
            window.addEventListener('keydown', onKeyDown);
        }

        document.addEventListener('mousedown', onMouseDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('mousedown', onMouseDown);
        }
    }, [footer, localeMenu]);

    const onAccordionClick = index => {
        if (timeout === null) {
            timeout = setTimeout(() => {
                timeout = null;

                const itemIndex = active.indexOf(index);

                if (itemIndex < 0) {
                    active.push(index);
                } else {
                    active.splice(itemIndex, 1);
                }

                setFooter({ ...footer, active: active });
            }, 200);
        }
    };

    const onDoubleClick = () => {
        clearTimeout(timeout);
        timeout = null;
    };

    return (
        <footer
            className="footer"
        >
            {['Legal Footer with localization', 'Legal Footer'].indexOf(footerType) < 0 &&
                <div className="footer__top">
                    <Link
                        url="/"
                        className="footer__top-logo"
                        dataQeId="footer-logo"
                        section={section}
                        parent={section}
                    >
                        <img width="100" height="100" src="https://images.ctfassets.net/nw29p03vv8ee/3PpcmF2GOeJ00YbheI07cO/07e26a2f5864bbd664710c2c3c5a87f3/ReSci-From-CTCT-Logo-RGB-Color_nav_bar.svg" alt="ReSci Logo"/>
                    </Link>
                    {subHeadingRichText &&
                        <TextBlock
                            {...subHeadingRichText}
                            className="footer__top-sub-heading"
                        />
                    }
                    <nav className="footer__top-nav">
                        <div className="footer__top-links">
                            {footerLinks && footerLinks.map((linkList,i) => {
                                const {
                                    contentful_id:cidLinkList,
                                    headline,
                                    id:idLinkList,
                                    links
                                } = linkList,
                                parent = {
                                    contentful_id: cidLinkList,
                                    id: idLinkList,
                                };

                                return (
                                    <React.Fragment key={i}>
                                        {['International Site'].indexOf(footerType) > -1 ? (
                                                <React.Fragment>
                                                    {links.map((link, intIndex) => {

                                                        const {
                                                            contentful_id,
                                                            linkText,
                                                            url:linkUrl,
                                                        } = link;

                                                        return (
                                                            <div className="footer__top-links-column" key={intIndex}>
                                                                <h4>
                                                                    <CtaButton
                                                                        className="footer__top-nav-int"
                                                                        contentful_id={contentful_id}
                                                                        dataQeId="footer-nav"
                                                                        eventActionText={linkText}
                                                                        href={linkUrl}
                                                                        parent={{contentful_id: 'hdg'}}
                                                                        section={section}
                                                                    >
                                                                        {linkText}
                                                                    </CtaButton>
                                                                </h4>
                                                            </div>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            ) : (
                                                <div className="footer__top-links-column">
                                                    <h4>
                                                        <CtaButton
                                                            id={i}
                                                            className={active.indexOf(i) < 0 ? null : 'open'}
                                                            dataQeId="footer-nav-toggle"
                                                            eventActionText={headline}
                                                            parent={{contentful_id: 'hdg'}}
                                                            section={section}
                                                            onClick={() => onAccordionClick(i)}
                                                            onDoubleClick={onDoubleClick()}
                                                            type="button"
                                                        >
                                                            {headline}
                                                            <div className={`arrow arrow--large ${active.indexOf(i) < 0 ? null : 'open'}`} />
                                                        </CtaButton>
                                                    </h4>
                                                    {['International Site'].indexOf(footerType) < 0 &&
                                                        <VelocityBandaid
                                                            animation={
                                                                active.indexOf(i) < 0
                                                                    ? 'slideUp'
                                                                    : 'slideDown'
                                                            }
                                                            duration={500}
                                                        >
                                                            <ul>
                                                                {links.map((link, j) => {
                                                                    const {
                                                                            contentful_id,
                                                                            dataTrackedAction: linkTrackedAction,
                                                                            id,
                                                                            linkText,
                                                                            url:linkUrl,
                                                                            target,
                                                                            ...attributes
                                                                        } = link,
                                                                        lid = {
                                                                            contentful_id: contentful_id
                                                                        };

                                                                    return (
                                                                        <li key={j}>
                                                                            <Link
                                                                                {...attributes}
                                                                                className={`${/http|https/.test(linkUrl) ? 'external' : ''}`}
                                                                                dataQeId="footer-link"
                                                                                link={lid}
                                                                                parent={parent}
                                                                                section={section}
                                                                                target={target}
                                                                                text={linkText}
                                                                                url={linkUrl}
                                                                            />
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </VelocityBandaid>
                                                    }
                                                </div>
                                            )
                                        }
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        {/* <div className="footer__top-social">
                            <div className="footer__top-social-media">
                                <ul>
                                 <li>
                                        <Link
                                            dataQeId="footer-social-facebook"
                                            eventActionText="Follow Us on Facebook"
                                            parent={{contentful_id: 'sfb'}}
                                            rel="noopener noreferrer"
                                            section={section}
                                            url="https://www.facebook.com/RetentionScience/"
                                            target="_blank"
                                        >
                                            <img
                                                alt="facebook-logo"
                                                height="19px"
                                                width="10px"
                                                src="https://images.ctfassets.net/nw29p03vv8ee/5mH7fQTXE5zCLrjaH3HZIN/8e5f46b1e4f914bdd6c540553e685f36/facebook_icon.svg"
                                                loading="lazy"
                                            />
                                            <span className="screen-reader-text">
                                                Follow Us on Facebook
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            dataQeId="footer-social-twitter"
                                            parent={{contentful_id: 'stw'}}
                                            rel="noopener noreferrer"
                                            section={section}
                                            url="https://twitter.com/retentionsci"
                                            target="_blank"
                                        >
                                            <img
                                                alt="twitter-logo"
                                                height="17px"
                                                width="21px"
                                                src="https://images.ctfassets.net/nw29p03vv8ee/2L1MwByAtd2iu4fgF8z1wE/f62f58bd52de2e0665e5eda4a02c8ee2/twitter_icon.svg"
                                                loading="lazy"
                                            />
                                            <span className="screen-reader-text">
                                                Follow Us on Twitter
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            dataQeId="footer-social-instagram"
                                            eventActionText="Follow Us on Instagram"
                                            parent={{contentful_id: 'sin'}}
                                            rel="noopener noreferrer"
                                            section={section}
                                            url="https://www.instagram.com/retentionscience/"
                                            target="_blank"
                                        >
                                            <img
                                                alt="instagram-logo"
                                                height="19px"
                                                width="19px"
                                                src="https://images.ctfassets.net/nw29p03vv8ee/q4ahkwzXBwsgXTImk0Z1q/851904b984a818680c4415340f174528/instagram_icon.svg"
                                                loading="lazy"
                                            />
                                            <span className="screen-reader-text">
                                                Follow Us on Instagram
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            dataQeId="footer-social-linkedin"
                                            eventActionText="Follow Us on LinkedIn"
                                            parent={{contentful_id: 'sli'}}
                                            rel="noopener noreferrer"
                                            section={section}
                                            url="https://www.linkedin.com/company/retention-science/"
                                            target="_blank"
                                        >
                                            <img
                                                alt="linkedin-logo"
                                                height="19px"
                                                width="20px"
                                                src="https://images.ctfassets.net/nw29p03vv8ee/68BDmKrqTyUQGgXG9PGbLZ/ee54715a8b2cfc663fe604e0e9a4d034/linkedin_icon.svg"
                                                loading="lazy"
                                            />
                                            <span className="screen-reader-text">
                                                Follow Us on LinkedIn
                                            </span>
                                        </Link>
                                    </li> 
                                    
                                </ul>
                            </div>
                            </div> */}
                    </nav>
                </div>
            }
            <div className="footer__bottom">
                <div className="footer__bottom-inner">
                    <div className={`footer__bottom-legal ${['Legal Footer'].indexOf(footerType) < 0 ? '' : 'footer__bottom-legal--centered'}`}>
                        <ul>
                            <li>
                                ©  Retention Science. {new Date().getFullYear()}
                            </li>
                            <li>
                                <Link
                                    dataQeId="footer-terms-and-policies"
                                    parent={{contentful_id: 't&cp'}}
                                    rel="nofollow"
                                    section={section}
                                    text="Terms &amp; Policies"
                                    url="/retention-science-terms-conditions"
                                />
                            </li>
                            <li>
                                <Link
                                    dataQeId="footer-privacy-policy"
                                    parent={{contentful_id: 'fpp'}}
                                    rel="nofollow"
                                    section={section}
                                    text="Privacy Policy"
                                    url="/privacy-policy"
                                />
                            </li>
                            <li>
                                <Link
                                    dataQeId="footer-data-processing-agreement"
                                    parent={{contentful_id: 'fdap'}}
                                    rel="nofollow"
                                    section={section}
                                    text="Data Processing Agreement"
                                    url="/resci-dpa"
                                />
                            </li>
                            <li>
                                <Link
                                    dataQeId="footer-vulnerability-disclosure"
                                    parent={{contentful_id: 'fvd'}}
                                    rel="nofollow"
                                    section={section}
                                    text="Vulnerability Disclosure"
                                    url="/disclosure"
                                />
                            </li>
                            {visibility &&
                                <li>
                                    <CtaButton
                                        buttonText="Cookie Settings"
                                        className="cookie-settings track-click"
                                        dataQeId="footer-cookie-settings"
                                        contentful_id="cookie-settings"
                                        parent={{contentful_id: 'fcs'}}
                                        section={section}
                                        type="button"
                                    />
                                </li>
                            }
                            <li>
                                <Link
                                    dataQeId="footer-do-not-sell-my-personal-information"
                                    parent={{contentful_id: 'fdn'}}
                                    rel="nofollow"
                                    section={section}
                                    text="Do Not Sell My Personal Information"
                                    url="/privacy-policy"
                                    buttonText="Do Not Sell My Personal Information"
                                    className="footer-do-not-sell track-click"
                                    contentful_id="footer-do-not-sell"
                                    type="button"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

FooterView.propTypes = {
    ctctLocalePref: PropTypes.string,
    iframeSource: PropTypes.string,
    footerType: PropTypes.string,
    heading: PropTypes.string,
    subHeading: PropTypes.object,
    footerLinks: PropTypes.array
};

FooterView.defaultProps = {
    subHeading: {
        subHeading: ''
    }
};

export default FooterView;
