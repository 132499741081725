import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Html = props => {
    const {
        html: {
            html
        }
    } = props,
    divRef = useRef(null);

    // Via https://github.com/christo-pr/dangerously-set-html-content
    useEffect(() => {
        if (!html) return;

        const slotHtml = document.createRange().createContextualFragment(html);
        divRef.current.innerHTML = '';
        divRef.current.appendChild(slotHtml);
    }, [html]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div ref={divRef} />
    );
}

Html.propTypes = {
    html: PropTypes.object
}

Html.defaultProps = {};

export default Html;
