import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './logo.scss';

const LogoWrapper = props => {
    const {
        headline,
        introText,
        logos,
        paragraphText,
    } = props,
    logosRef = useRef(null);


    const getRandomLogo = (activeLogos) => {

        let randomLogo;

        const getUniqueLogo = () => {
            // Get a random logo from the list of available logos
            randomLogo = logos[_.random(0, logos.length - 1)];

            const {
                logo: {
                    file: {
                        url
                    }
                }
            } = randomLogo;

            // Check if the randomly selected logo is currently visible
            activeLogos.forEach(item => {
                const itemURL = item.querySelector('img').src;

                // If the randomly selected logo is visible get another logo
                if (itemURL.includes(url)) {
                    randomLogo = getUniqueLogo();
                }
            });

            // If the above doesn't restart getUniqueLogo return the logo
            return randomLogo;
        }

        return getUniqueLogo();
    }

    useEffect(() => {
        let lastIndex;
        const switchLogo = () => {

            const logoImages = logosRef.current.querySelectorAll('.logos__logo'),
                randomLogoImagesIndex = _.random(0, logoImages.length - 1),
                randomLogo = getRandomLogo(logoImages),
                {
                    alternativeText,
                    logo: {
                        file: {
                            url
                        }
                    }
                } = randomLogo;

            let randomIndex = randomLogoImagesIndex,
                logoTarget = logoImages[randomLogoImagesIndex],
                logoTargetImage = logoTarget.querySelector('img');

            // Bump the index if it was used last time around
            if (lastIndex === randomLogoImagesIndex) {
                if (randomLogoImagesIndex > 0 && randomLogoImagesIndex <= logoImages.length) {
                    randomIndex--;
                } else if (randomLogoImagesIndex === 0) {
                    randomIndex++;
                }

                logoTarget = logoImages[randomIndex];
                logoTargetImage = logoTarget.querySelector('img');
            }

            logoTargetImage.classList.remove('fadeIn');
            logoTargetImage.classList.add('fadeOut', 'fast', 'animated');
            logoTarget.insertAdjacentHTML('beforeend', `
                <img
                    alt=${alternativeText}
                    class="fadeIn fast animated"
                    src=${url}
                    loading="lazy"
                />
            `);

            // Clean up the old logo
            setTimeout(() => {
                const garbage = logoTarget.querySelector('.fadeOut');
                garbage.parentNode.removeChild(garbage);
            }, 800); // 800ms is the animation-duration for "fast"

            lastIndex = randomIndex;

            setTimeout(switchLogo, 3000);
        };

        // Only fire if there are more than 5 logos
        if (logos.length > 5) {
            switchLogo();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="logos">
            {(headline || introText || paragraphText) && (
                <div className="logos__header">
                    {introText && (
                        <p className="logos__intro-text">{introText}</p>
                    )}
                    {headline && <h2>{headline}</h2>}
                    {paragraphText && (
                        <p className="logos__paragraph-text">{paragraphText}</p>
                    )}
                </div>
            )}
            <div className="logos__body" ref={logosRef}>
                {logos &&
                    logos.map((logo, i) => {

                        // Only show a max of 5 logos
                        if (i >= 5) {
                            return false;
                        }

                        const {
                            alternativeText,
                            logo: {
                                file: { url: logoUrl },
                            },
                        } = logo;

                        return (
                            <div className="logos__logo" key={i}>
                                <img
                                    src={logoUrl}
                                    alt={alternativeText}
                                    loading="lazy"
                                />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

LogoWrapper.propTypes = {
    headline: PropTypes.string,
    introText: PropTypes.string,
    paragraphText: PropTypes.string,
    logos: PropTypes.arrayOf(
        PropTypes.shape({
            alternativeText: PropTypes.string.isRequired,
            logo: PropTypes.object.isRequired
        })
    )
};

export default LogoWrapper;
